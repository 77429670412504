// @flow
import * as React from 'react';
import Select from 'react-select';
import type { InputActionMeta, ValueType } from 'react-select/src/types';
import {
    memoize, debounce, isEqual, get, isEmpty,
} from 'lodash';
import { connectAPI } from 'speed-js-react';
import ApiClient from 'speed-js-core/src/api/ApiClient';
import CreatableSelect from 'react-select/creatable';
import memoizeOne from 'memoize-one';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';


type Props = {
    choices: {
        [any]: string,
    },
    onChange: (value: ValueType) => void,
    onBlur: (e: SyntheticFocusEvent<any>) => void,
    initialObjectId: any,
    value: any;
    name: string;
    multi: boolean,
    placeholder: string,
    emptyLabel: string,
    // eslint-disable-next-line
    resourceName: string,
    // eslint-disable-next-line
    resourceMethod: string,
    // eslint-disable-next-line
    resourceArguments?: string[],
    // eslint-disable-next-line
    initialOptionResourceMethod: string,
    // eslint-disable-next-line
    searchField: string,
    // eslint-disable-next-line
    additionalFilters: Object,
    // eslint-disable-next-line
    valueField: string,
    valueTypePK: boolean,
    valueFields: Array,
    // eslint-disable-next-line
    labelFields: Array<string>,
    formatLabel: (option: Object, props: Props) => string,
    minSearchLength: number,
    preload: boolean,
    cacheOptions: boolean,
    styles: Object,
    setFirstVal: Function,
    additionalValueFilters: Object,
    initialSearchText: string,
    menuPortalTarget?: Node,
    menuPlacement?: string,
    closeMenuOnSelect?: boolean,
    creatable?: boolean,
    intl: IntlShape,
    noOptionsMessage?: () => React.Node,
    disabled?: boolean,
}

type State = {
    loading: boolean,
    options: Array<{ label: string, value: any }>,
    selectedValue: Array<{ label: string, value: any }>,
}

type CombinedProps = Props & {
    getData: (searchText: string) => Promise<Object>,
    getInitialOption: (value: string) => Promise<Object>,
}


const defaultFormatLabel = (option: Object, props: Props) => {
    let label = '';
    if (props.labelFields) {
        props.labelFields.forEach((lf) => {
            if (get(option, lf)) label = `${label} ${get(option, lf)}`;
        });
    }

    return label;
};

const createManualOption = (value: string, label: string) => ({
    value,
    label,
    _original: {},
});

const messages = defineMessages({
    creatablePrefix: {
        id: 'creatable-prefix',
        defaultMessage: 'Create',
    },
});

@connectAPI((apiClient: ApiClient, props: Props) => ({
    getData: {
        send: (searchText: Object) => get(apiClient.resources, props.resourceName)[props.resourceMethod](
            ...props.resourceArguments,
            {
                [props.searchField]: searchText,
                ...props.additionalFilters,
            },
        ),
    },
    getInitialOption: {
        send: (value: string) => get(apiClient.resources, props.resourceName)[props.initialOptionResourceMethod](value),
    },
}), true)
class AsyncSelect extends React.Component<CombinedProps, State> {
    select: { current: ?typeof Select };

    cachedOptions = {};

    constructor(props: CombinedProps) {
        super(props);

        const { multi, emptyLabel } = props;

        this.state = {
            options: !multi ? [{
                label: emptyLabel,
                value: null,
                _original: {},
            }] : [],
            loading: false,
            selectedValue: null,
        };

        this.getValue = memoize(this.getValue);
        this.appendManualOptions = memoizeOne(this.appendManualOptions);
        this.loadOptions = debounce(this.loadOptions, 200);

        this.select = React.createRef();
    }

    componentDidMount() {
        const {
            value, initialObjectId, valueTypePK,
        } = this.props;

        if (valueTypePK && (initialObjectId || value)) {
            this.loadInitialOption();
        } else {
            this.loadOptions('');
        }
    }

    componentDidUpdate(prevProps: CombinedProps) {
        const {
            resourceName, resourceMethod, additionalFilters, preload, value, initialSearchText,
        } = this.props;
        const { value: prevValue } = prevProps;

        if (
            resourceName !== prevProps.resourceName
            || resourceMethod !== prevProps.resourceMethod
            || !isEqual(additionalFilters, prevProps.additionalFilters)
            || initialSearchText !== prevProps.initialSearchText
            || value !== prevValue
        ) {
            if (preload) {
                this.clearOptions();
                this.clearCache();
                this.loadOptions('');
            }
        }

        // Force update the select when value is changed because when multi select is used
        // and input field size grows the menu drop is not repositioned if rendered in a portal
        if (value !== prevValue) {
            if (this.select?.current?.select) {
                this.select.current.select.forceUpdate();
            }
        }
    }

    componentWillUnmount() {
        this.clearCache();
    }

    getValue = (value: any, options: Array<{ label: string, value: any }>): any => {
        const { setFirstVal, additionalValueFilters, multi } = this.props;

        if (multi) {
            const val = value ? value.map((v) => v.toString()) : [];
            const result = options.filter((opt) => val.indexOf(opt.value) > -1);
            return result.length ? result : null;
        }

        const val = value ? value.toString() : value;
        let valueResults = options.filter((v) => (v.value != null));

        Object.keys(additionalValueFilters).forEach((key) => {
            valueResults = valueResults.filter((v) => v._original[key] === additionalValueFilters[key]);
        });

        if (setFirstVal && valueResults.length === 1) {
            setFirstVal(valueResults[0].value);
            return valueResults[0];
        }

        return options.find((opt) => opt.value?.toString() === val) || null;
    };

    getOptionValue = (option: { label: string, value: any }) => option.value || '';

    handleChange = (value?: Object) => {
        const { onChange, multi } = this.props;

        this.setState({
            selectedValue: value,
        });

        if (multi) {
            onChange(value ? value.map((v) => v.value) : null, value ? value.map((v) => v._original) : null);
        } else {
            onChange(value ? value.value : null, value ? value._original : null);
        }
    };

    handleInputChange = (inputValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change' || actionMeta.action === 'input-blur') {
            this.loadOptions(inputValue, true);
        }
    };

    setInitialOptions = (response: Object) => {
        const {
            formatLabel, valueField, multi, emptyLabel, valueFields,
        } = this.props;
        if (response && response[valueField]) {
            const options = !multi ? [{
                label: emptyLabel,
                value: null,
                _original: {},
            }] : [];

            const getLabel = formatLabel || defaultFormatLabel;
            const arr = [];
            if (valueFields) valueFields.forEach((v) => (response[v] && arr.push(response[v].toString())));

            options.push({
                label: getLabel(response, this.props),
                value: arr.length > 0 ? arr.join('-') : response[valueField],
                _original: response,
            });

            this.getValue.cache.clear();
            this.setState({
                options,
                loading: false,
            });
        }
    };

    loadInitialOption = () => {
        const { getInitialOption, initialObjectId: objectId, value } = this.props;

        this.setState({ loading: true });
        if (objectId) {
            getInitialOption(objectId).then((response: Object) => this.setInitialOptions(response));
        } else {
            getInitialOption(value).then((response: Object) => this.setInitialOptions(response));
        }
    };

    setOptions = (response: Object, searchText: string) => {
        const {
            formatLabel, valueField, multi, emptyLabel, valueFields, cacheOptions,
        } = this.props;
        const options = !multi ? [{
            label: emptyLabel,
            value: null,
            _original: {},
        }] : [];

        if (response.results) {
            const getLabel = formatLabel || defaultFormatLabel;

            response.results.forEach((res) => {
                const arr = [];
                if (valueFields) valueFields.forEach((v) => (res[v] && arr.push(res[v].toString())));
                options.push({
                    label: getLabel(res, this.props),
                    value: arr.length > 0 ? arr.join('-') : res[valueField],
                    _original: res,
                });
            });
        }
        this.getValue.cache.clear();

        if (cacheOptions) {
            this.cachedOptions[searchText] = options;
        }
        this.setState({
            options,
            loading: false,
        });
    };

    loadOptions = (searchText: string, fromInputChange = false) => {
        const {
            getData, minSearchLength, cacheOptions, initialSearchText,
        } = this.props;
        if (searchText.length >= minSearchLength) {
            if (cacheOptions && typeof this.cachedOptions[searchText] !== 'undefined') {
                this.setState({
                    options: this.cachedOptions[searchText],
                });
            }

            this.setState({ loading: true });
            if (initialSearchText && !fromInputChange) {
                getData(initialSearchText).then((response: Object) => this.setOptions(response, initialSearchText));
            } else {
                getData(searchText).then((response: Object) => this.setOptions(response, searchText));
            }
        } else {
            this.clearOptions();
            this.clearCache();
            this.loadOptions('');
        }
    };

    appendselectedValue = (options: Object): Array<{ label: string, value: any }> => {
        const { multi } = this.props;
        const { selectedValue } = this.state;

        if (!isEmpty(selectedValue)) {
            // Append manually created options
            if (options) {
                if (multi) {
                    selectedValue.forEach((value) => {
                        if (isEmpty(options.filter((option) => (option.value === value.value)))) {
                            options.push(createManualOption(value.value, value.label));
                        }
                    });
                } else if (selectedValue.value
                    && isEmpty(options.filter((option) => (option.value === selectedValue.value)))
                ) {
                    options.push(createManualOption(selectedValue.value, selectedValue.label));
                }
            } else if (multi) {
                selectedValue.forEach((value) => options.push(createManualOption(value.value, value.label)));
            } else if (selectedValue.value) {
                options.push(createManualOption(selectedValue.value, selectedValue.label));
            }
        }

        return options;
    };

    formatCreateLabel = (inputValue: string) => {
        const { intl: { formatMessage } } = this.props;
        return `${formatMessage(messages.creatablePrefix)} "${inputValue}"`;
    };

    clearCache() {
        this.cachedOptions = {};
    }

    clearOptions() {
        this.setState({ options: [] });
    }

    render() {
        const {
            name, onBlur, value, multi, styles, placeholder, menuPortalTarget, menuPlacement, closeMenuOnSelect,
            creatable, noOptionsMessage, disabled,
        } = this.props;

        const { loading, options, selectedValue } = this.state;

        const optionsResult = this.appendselectedValue(options);

        return creatable ? (
            <CreatableSelect
                placeholder={placeholder}
                options={optionsResult}
                onChange={this.handleChange}
                value={this.getValue(value, optionsResult)}
                name={name}
                id={`id-${name}`}
                isMulti={multi}
                onBlur={onBlur}
                closeMenuOnSelect={typeof closeMenuOnSelect !== 'undefined' ? closeMenuOnSelect : !multi}
                isClearable
                getOptionValue={this.getOptionValue}
                onInputChange={this.handleInputChange}
                isLoading={loading}
                ref={this.select}
                styles={styles}
                menuPortalTarget={menuPortalTarget}
                menuPlacement={menuPlacement}
                formatCreateLabel={this.formatCreateLabel}
                noOptionsMessage={noOptionsMessage}
                isDisabled={disabled}
            />
        ) : (
            <Select
                placeholder={placeholder}
                options={optionsResult}
                onChange={this.handleChange}
                value={this.getValue(value, optionsResult)}
                name={name}
                id={`id-${name}`}
                isMulti={multi}
                onBlur={onBlur}
                closeMenuOnSelect={typeof closeMenuOnSelect !== 'undefined' ? closeMenuOnSelect : !multi}
                isClearable
                getOptionValue={this.getOptionValue}
                onInputChange={this.handleInputChange}
                isLoading={loading}
                ref={this.select}
                styles={styles}
                menuPortalTarget={menuPortalTarget}
                menuPlacement={menuPlacement}
                noOptionsMessage={noOptionsMessage}
                isDisabled={disabled}
            />
        );
    }
}

AsyncSelect.defaultProps = {
    searchField: 'search',
    resourceMethod: 'list',
    initialOptionResourceMethod: 'get',
    valueField: 'id',
    valueTypePK: true,
    labelFields: [],
    formatLabel: null,
    minSearchLength: 3,
    additionalValueFilters: {},
    additionalFilters: {},
    preload: false,
    cacheOptions: true,
    menuPortalTarget: undefined,
    menuPlacement: undefined,
    closeMenuOnSelect: undefined,
    resourceArguments: [],
    creatable: false,
    intl: {},
    noOptionsMessage: undefined,
    disabled: false,
};

export default injectIntl(AsyncSelect, { forwardRef: true });
