// @flow

import Resource from 'speed-js-core/src/api/Resource';

export default class ParkingResource extends Resource {
    basePath: string = 'parkings/parkings/';

    objectPath: string = 'parkings/parkings/{ident}/';

    parkingInfo(id: string): Promise<Object> {
        return this.client._request(`${this._getObjectPath(id)}parking_info/`, 'GET')
            .then((response) => response.data);
    }
}
