// @flow
import { createAction } from 'redux-actions';
import {
    PUSH_BREADCRUMB, POP_BREADCRUMB, PUSH_MULTIPLE_BREADCRUMBS, SET_PAGE_TITLE,
} from './constants';
import type { Breadcrumb } from './reducer';

export const setPageTitle = createAction(SET_PAGE_TITLE);

export const addBreadcrumb = createAction(
    PUSH_BREADCRUMB, (title: string, link: string) => ({ title, link }),
);

export const addBreadcrumbs = createAction(
    PUSH_MULTIPLE_BREADCRUMBS, (breadcrumbs: Array<Breadcrumb>) => ({ breadcrumbs }),
);

export const removeBreadcrumb = createAction(
    POP_BREADCRUMB, (title: string) => ({ title }),
);
