// @flow

import Resource from 'speed-js-core/src/api/Resource';
import AuthorisedPersonResource from './AuthorisedPersonResource';
import ContactPersonResource from './ContactPersonResource';
import ZoneResource from './ZoneResource';
import ZoneGeoResource from './ZoneGeoResource';
import SiteEquipmentResource from './SiteEquipmentResource';

export default class SiteResource extends Resource {
    basePath: string = 'sites/sites/';

    objectPath: string = 'sites/sites/{ident}/';

    nestedResources = {
        authorisedPeople: AuthorisedPersonResource,
        contactPeople: ContactPersonResource,
        zones: ZoneResource,
        zonesGeo: ZoneGeoResource,
        equipment: SiteEquipmentResource,
    };

    authorisedPeople: AuthorisedPersonResource;
}
