// @flow

import Resource from './AuthResource';


const getContextAuthResource = (contextName: string): Class<Resource> => (
    class AuthResource extends Resource {
        basePath: string = `auth/${contextName}/`;
    }
);

export default getContextAuthResource;
