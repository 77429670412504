// @flow
import Resource from 'speed-js-core/src/api/Resource';
import ServiceAccessNestedResource from './ServiceAccessNestedResource';
import ServiceAssetNestedResource from './ServiceAssetNestedResource';

export default class ServiceZoneResource extends Resource {
    basePath: string = 'services/service-zones/';

    objectPath: string = 'services/service-zones/{ident}/';
}
