// @flow
import { ACTIONS_NAMESPACE } from '../constants';

export const MOUNT_POINT = 'auth';

export const SET_USER = `${ACTIONS_NAMESPACE}/SET_USER`;

export const REMOVE_USER = `${ACTIONS_NAMESPACE}/REMOVE_USER`;

export const SET_PERMISSIONS = `${ACTIONS_NAMESPACE}/SET_PERMISSIONS`;

export const RESET_AUTH_STATE = `${ACTIONS_NAMESPACE}/RESET_AUTH_STATE`;
