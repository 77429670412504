// @flow

import { get } from 'lodash';
import * as React from 'react';
import { defineMessages } from 'react-intl';
// Import TinyMCE
import tinymce from 'tinymce/tinymce';

// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';

import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/preview';

import { Editor } from '@tinymce/tinymce-react';
import * as Yup from 'yup';
import FieldWrapper from './FieldWrapper';


window.tinymce = tinymce;


type Props = {
    field: {
        onChange: (e: SyntheticInputEvent<number>) => void,
        onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
        onBlur: (e: SyntheticFocusEvent<any>) => void,
        value: any;
        name: string;
    },
    form: {
        errors: Object,
        touched: Object,
        isSubmitting: boolean,
        setFieldValue: Function,
    },
    required: boolean,
    label: string,
    help_text: string,
    disabled?: boolean,
}

const RichTextEditor = (props: Props) => {
    const {
        field: {
            name, value,
        },
        form: {
            isSubmitting, setFieldValue, errors,
        },
        label,
        help_text: help,
        required,
        disabled,
    } = props;

    const errs = get(errors, name);

    const handleChange = (content, editor) => {
        setFieldValue(name, content);
    };

    return (
        <FieldWrapper
            name={name}
            required={required}
            helpText={help}
            label={label}
        >
            <div className={errs ? 'is-invalid' : null}>
                <Editor
                    value={value || '<p/>'}
                    init={{
                        plugins: ['autolink link lists preview'],
                        base_url: '/static/tinymce',
                        toolbar:
                            `styleselect | bold italic forecolor backcolor removeformat |
                            alignleft aligncenter alignright alignjustify |
                            bullist numlist outdent indent | link`,
                        menubar: false,
                    }}
                    disabled={disabled || isSubmitting}
                    onEditorChange={handleChange}
                />
            </div>
        </FieldWrapper>
    );
};

const messages = defineMessages({
    min: {
        id: 'rich-text-min',
        defaultMessage: 'The length of the text must be more than {minLimit} characters!',
    },
    max: {
        id: 'rich-text--max',
        defaultMessage: 'The length of the text must be less than {maxLimit} characters!',
    },
    required: {
        id: 'rich-text--required',
        defaultMessage: 'This field is required.',
    },
});

RichTextEditor.getValidationSchema = (field: { validation: Object }) => {
    let errMsg = Yup.string().nullable();
    if (field.validation.required) {
        errMsg = Yup.string().required(messages.required).nullable();
    }
    if (field.validation.min_length) {
        errMsg = errMsg.min(field.validation.min_length.toString(),
            { ...messages.min, values: { minLimit: field.validation.min_length.toString() } });
    }
    if (field.validation.max_length) {
        errMsg = errMsg.max(field.validation.max_length.toString(),
            { ...messages.max, values: { maxLimit: field.validation.max_length.toString() } });
    }

    return errMsg;
};

RichTextEditor.defaultProps = {
    disabled: false,
};

export default RichTextEditor;
