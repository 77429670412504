// @flow

import { ACTIONS_NAMESPACE } from '../constants';

export const SET_PAGE_TITLE = `${ACTIONS_NAMESPACE}/SET_PAGE_TITLE`;
export const PUSH_BREADCRUMB = `${ACTIONS_NAMESPACE}/PUSH_BREADCRUMB`;
export const PUSH_MULTIPLE_BREADCRUMBS = `${ACTIONS_NAMESPACE}/PUSH_MULTIPLE_BREADCRUMBS`;
export const POP_BREADCRUMB = `${ACTIONS_NAMESPACE}/POP_BREADCRUMB`;

export const MOUNT_POINT = 'page';
