// @flow

import Resource from 'speed-js-core/src/api/Resource';

export default class BillResource extends Resource {
    basePath: string = 'billing/bill/';

    objectPath: string = 'billing/bill/{ident}/';

    changeStatus(data): Promise<Object> {
        return this.client._request(`${this.basePath}change_status/`, 'POST', data)
            .then((response) => response.data);
    }
}
