// @flow

import Resource from 'speed-js-core/src/api/Resource';
import RepresentativeResource from './OrganisationRepresentative';

export default class OrganisationResource extends Resource {
    basePath: string = 'entities/organisations/';

    objectPath: string = 'entities/organisations/{ident}/';

    nestedResources = {
        representatives: RepresentativeResource,
    };

    representatives: RepresentativeResource;
}
