// @flow

import * as React from 'react';
import { get } from 'lodash';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import CoordinatesPicker from 'speed-js-react/src/components/coordinatespicker/CoordinatesPicker';
import * as Yup from 'yup';
import {
    defineMessages, FormattedMessage, injectIntl, IntlShape,
} from 'react-intl';
import NumberInputCoordinates from './NumberInputCoordinates';
import FieldWrapper from './FieldWrapper';

type Props = {
    field: {
        onChange: Function,
        onBlur: Function,
        value: any,
        name: string,
    },
    form: {
        setFieldTouched: Function,
        setFieldValue: Function,
        errors: string,
    },
    required: boolean,
    intl: IntlShape,
};

const messages = defineMessages({
    required: {
        id: 'coordinate-input-required',
        defaultMessage: 'This field is required.',
    },
    lat: {
        id: 'lat-placeholder',
        defaultMessage: 'Lat',
    },
    lng: {
        id: 'lng-placeholder',
        defaultMessage: 'Lng',
    },
});

const StyledRow = styled(Row)`
    margin: -6px;
    display: flex;
    flex-wrap: nowrap;
`;

const CoordinatesPickerInput = (props: Props) => {
    const {
        field, form, intl, required,
    } = props;
    const [coordinates, setCoordinates] = React.useState(null);

    const handleInputChange = (e, v) => {
        field.onChange(e);
        field.onBlur(v);
        const coords = [
            v.split(',')[0] !== null ? Number(v.split(',')[0]) : null,
            v.split(',')[1] !== null ? Number(v.split(',')[1]) : null,
        ];
        setCoordinates({
            type: 'Point',
            coordinates: coords,
        });
        form.setFieldValue(field.name, {
            type: 'Point',
            coordinates: coords,
        });
    };

    const latValue = coordinates?.coordinates[0] || field?.value?.coordinates[0];
    const lngValue = coordinates?.coordinates[1] || field?.value?.coordinates[1];

    const errs = get(form.errors, field.name);

    return (
        <FieldWrapper
            label={<FormattedMessage id="coordinates-picker-label" defaultMessage="Coordinates" />}
            name={field.name}
            required={required}
        >
            <div className={errs && 'is-invalid'}>
                <StyledRow className="flex-gap">
                    <CoordinatesPicker
                        lng={NumberInputCoordinates}
                        lat={NumberInputCoordinates}
                        lngValue={lngValue}
                        latValue={latValue}
                        lngPlaceholder={intl.formatMessage(messages.lng)}
                        latPlaceholder={intl.formatMessage(messages.lat)}
                        coordinates={coordinates}
                        onChange={handleInputChange}
                        onBlur={field.onBlur}
                        field={{
                            ...field,
                            value: coordinates,
                        }}
                        required
                    />
                </StyledRow>
            </div>
        </FieldWrapper>
    );
};

CoordinatesPickerInput.getValidationSchema = (field: { validation: Object }) => {
    if (field.validation.required) {
        return Yup.object().shape({
            type: Yup.string(),
            coordinates: Yup.array().of(Yup.number().required(messages.required)),
        }).required();
    }

    return Yup.string().nullable();
};

export default injectIntl(CoordinatesPickerInput);
