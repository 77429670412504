// @flow
import * as React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import {
    defineMessages,
} from 'react-intl';
import FieldWrapper from './FieldWrapper';


type Props = {
    field: {
        onChange: (e: SyntheticInputEvent<number>) => void,
        onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
        onBlur: (e: SyntheticFocusEvent<any>) => void,
        value: any;
        name: string;
    },
    form: {
        errors: Object,
        touched: Object,
        isSubmitting: boolean,
    },
    required: boolean,
    label: string,
    help_text: string,
    defaultValue: number,
    min: number,
    max: number,
    disabled?: boolean,
}


const NumberInput = (props: Props): React.Node => {
    const {
        disabled,
        field: {
            name, onChange, onBlur, value,
        },
        form: { errors, touched, isSubmitting },
        label,
        help_text: help,
        required,
        min,
        max,
        defaultValue,
    } = props;

    let _value = value;
    // The check is to avoid passing undefined or null to Field because an error for
    // uncontrolled and controlled component is thrown
    if (value !== 0 && !value) {
        _value = defaultValue || '';
    }

    const errs = get(errors, name);

    return (
        <FieldWrapper
            name={name}
            required={required}
            helpText={help}
            label={label}
        >
            <Field
                className={errs ? 'form-control is-invalid' : 'form-control'}
                name={name}
                onChange={onChange}
                placeholder={label}
                onBlur={onBlur}
                value={_value}
                id={`id-${name}`}
                disabled={disabled || isSubmitting}
                min={min}
                max={max}
                type="number"
            />
        </FieldWrapper>
    );
};


const messages = defineMessages({
    required: {
        id: 'numberinput-required',
        defaultMessage: 'This field is required.',
    },
    min: {
        id: 'numberinput-min',
        defaultMessage: 'The value must be greater than or equal to {minValue}!',
    },
    max: {
        id: 'numberinput-max',
        defaultMessage: 'The value must be less than or equal to {maxValue}!',
    },
});

NumberInput.getValidationSchema = (field: { validation: Object }) => {
    let errMsg = Yup.number().nullable();
    if (field.validation.required) {
        errMsg = errMsg.required(messages.required).nullable();
    }
    if (typeof field.validation.min_value !== 'undefined' && field.validation.min_value !== null) {
        errMsg = errMsg.min(field.validation.min_value.toString(),
            { ...messages.min, values: { minValue: (field.validation.min_value).toString() } });
    }
    if (typeof field.validation.max_value !== 'undefined' && field.validation.max_value !== null) {
        errMsg = errMsg.max(field.validation.max_value.toString(),
            { ...messages.max, values: { maxValue: field.validation.max_value.toString() } });
    }

    return errMsg;
};

NumberInput.defaultProps = {
    disabled: false,
};

export default NumberInput;
