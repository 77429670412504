// @flow
import * as React from 'react';
import { memoize } from 'lodash';
import { ApiClient } from 'speed-js-core';
import Context from './context';

type Props = {
    apiClient: ApiClient,
    children: React.Node,
}

export default class APIProvider extends React.Component<Props> {
    getContext: () => Object;

    constructor(props: Props) {
        super(props);

        this.getContext = memoize(this._getContext, () => this.props);
    }

    _getContext = () => {
        const { apiClient } = this.props;

        return {
            apiClient,
        };
    };

    render() {
        const { children } = this.props;

        return (
            <Context.Provider value={this.getContext()}>
                {children}
            </Context.Provider>
        );
    }
}
