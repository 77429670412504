// @flow

import Resource from 'speed-js-core/src/api/Resource';
import PersonResource from './PersonResource';
import OrganisationResource from './OrganisationResource';
import SiteResource from './SiteResource';
import ServiceResource from './ServiceResource';
import ChangeClientType from './ChangeClientTypeResource';

export default class ClientResource extends Resource {
    basePath: string = 'clients/clients/';

    objectPath: string = 'clients/clients/{ident}/';

    nestedResources = {
        services: ServiceResource,
        sites: SiteResource,
        person: PersonResource,
        organisation: OrganisationResource,
        changeType: ChangeClientType,
    };

    sites: SiteResource;

    services: ServiceResource;

    person: PersonResource;

    organisation: OrganisationResource;

    changeType: ChangeClientType
}
