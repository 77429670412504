// @flow
import * as React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import type { ValueType } from 'react-select/src/types';
import memoizeOne from 'memoize-one';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';


type Props = {
    choices: {
        [any]: string,
    },
    onChange: (value: ValueType) => void,
    onBlur: (e: SyntheticFocusEvent<any>) => void,
    value: any;
    name: string;
    multi: boolean,
    emptyLabel: string,
    disabled: boolean,
    setFirstVal: Function,
    styles: Object,
    placeholder?: string,
    menuPortalTarget?: Node,
    menuPlacement?: string,
    closeMenuOnSelect?: boolean,
    creatable?: boolean,
    intl: IntlShape,
    noOptionsMessage?: () => React.Node,
}

const createManualOption = (label: string) => ({
    label,
    value: label,
});

const messages = defineMessages({
    creatablePrefix: {
        id: 'creatable-prefix',
        defaultMessage: 'Create',
    },
});

class SyncSelect extends React.Component<Props> {
    select: { current: ?typeof Select };

    static defaultProps = {
        disabled: false,
    };

    constructor(props: Props) {
        super(props);

        this.getOptions = memoizeOne(this.getOptions);
        this.getValue = memoizeOne(this.getValue);

        this.select = React.createRef();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const { value } = this.props;
        const { value: prevValue } = prevProps;

        // Force update the select when value is changed because when multiselect is used
        // and input field size grows the menu drop is not repositioned if rendered in a portal
        if (value !== prevValue) {
            if (this.select?.current?.select) {
                this.select.current.select.forceUpdate();
            }
        }
    }

    getOptions = (choices: Object, values): Array<{ label: string, value: any }> => {
        const { emptyLabel, multi, creatable } = this.props;

        const result = !multi ? [{
            label: emptyLabel,
            value: null,
        }] : [];
        if (choices) {
            Object.keys(choices).forEach((key) => {
                result.push({
                    label: choices[key],
                    value: key,
                });
            });
        }
        if (creatable && values) {
            // Append manually created options
            if (choices) {
                const choicesKeys = Object.keys(choices);
                if (multi) {
                    values.forEach((value) => {
                        if (choicesKeys.indexOf(value) === -1) {
                            result.push(createManualOption(value));
                        }
                    });
                } else if (choicesKeys.indexOf(values) === -1) {
                    result.push(createManualOption(values));
                }
            } else if (multi) {
                values.forEach((value) => result.push(createManualOption(value)));
            } else {
                result.push(createManualOption(values));
            }
        }

        return result;
    };

    formatCreateLabel = (inputValue: string) => {
        const { intl: { formatMessage } } = this.props;
        return `${formatMessage(messages.creatablePrefix)} "${inputValue}"`;
    };

    getValue = (value: any, options: Array<{ label: string, value: any }>): any => {
        const { multi, setFirstVal } = this.props;
        const valueResults = options.filter((v) => (v.value != null));
        if (setFirstVal && valueResults.length === 1) {
            setFirstVal(valueResults[0].value);
            return valueResults[0];
        }

        if (!multi) {
            const val = typeof value !== 'undefined' && value !== '' && value !== null ? value.toString() : value;
            return options.find((opt) => opt.value === val) || null;
        }

        const val = value ? value.map((v) => v.toString()) : [];
        const result = options.filter((opt) => val.indexOf(opt.value) > -1);
        return result.length ? result : null;
    };

    getOptionValue = (option: { label: string, value: any }) => option.value || '';

    handleChange = (value?: Object) => {
        const { onChange, multi } = this.props;
        if (multi) {
            onChange(value ? value.map((v) => v.value) : []);
        } else {
            onChange(value ? value.value : null);
        }
    };

    render() {
        const {
            name, onBlur, value, choices, multi, disabled, styles, menuPortalTarget, menuPlacement,
            placeholder, closeMenuOnSelect, creatable, noOptionsMessage,
        } = this.props;

        const options = this.getOptions(choices, value);

        return creatable ? (
            <CreatableSelect
                placeholder={placeholder}
                isDisabled={disabled}
                options={options}
                closeMenuOnSelect={typeof closeMenuOnSelect !== 'undefined' ? closeMenuOnSelect : !multi}
                onBlur={onBlur}
                isClearable
                name={name}
                id={`id-${name}`}
                isMulti={multi}
                onChange={this.handleChange}
                value={this.getValue(value, options)}
                getOptionValue={this.getOptionValue}
                ref={this.select}
                styles={styles}
                menuPlacement={menuPlacement}
                menuPortalTarget={menuPortalTarget}
                formatCreateLabel={this.formatCreateLabel}
                noOptionsMessage={noOptionsMessage}
            />
        ) : (
            <Select
                placeholder={placeholder}
                isDisabled={disabled}
                options={options}
                onChange={this.handleChange}
                value={choices && Object.keys(choices).length > 0 ? this.getValue(value, options) : null}
                name={name}
                id={`id-${name}`}
                isMulti={multi}
                closeMenuOnSelect={typeof closeMenuOnSelect !== 'undefined' ? closeMenuOnSelect : !multi}
                onBlur={onBlur}
                isClearable
                getOptionValue={this.getOptionValue}
                ref={this.select}
                styles={styles}
                menuPlacement={menuPlacement}
                menuPortalTarget={menuPortalTarget}
                noOptionsMessage={noOptionsMessage}
            />
        );
    }
}

SyncSelect.defaultProps = {
    closeMenuOnSelect: undefined,
    menuPortalTarget: undefined,
    menuPlacement: undefined,
    placeholder: undefined,
    creatable: false,
    intl: {},
    noOptionsMessage: undefined,
};

export default injectIntl(SyncSelect, { forwardRef: true });
