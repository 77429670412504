// @flow
import React, { useState } from 'react';
import { BlockPicker, ChromePicker } from 'react-color';
import { defineMessages } from 'react-intl';
import { Input } from 'reactstrap';
import * as Yup from 'yup';
import FieldWrapper from './FieldWrapper';

type Props = {
    field: {
        value: any;
        name: string;
    },
    form: {
        setFieldValue: (string, any) => void,
    },
    required: boolean,
    label: string,
    help_text: string,
    type: string,
}

const TYPES = {
    small: BlockPicker,
    full: ChromePicker,
};

const modalOutlineStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'black',
    opacity: '0.5',
    zIndex: 1,
};

const pickerWrapperStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
};

const pickerStyles = {
    position: 'absolute',
    pointerEvents: 'initial',
};

const ColorPicker = (props: Props) => {
    const {
        field: {
            name, value,
        },
        form: { setFieldValue },
        label,
        help_text: help,
        required,
        type,
    } = props;

    const Picker = TYPES[type];

    const [showPicker, setShowPicker] = useState(false);
    const [displayValue, setDisplayValue] = useState(value);

    const handleClose = () => setShowPicker(false);
    const handleChange = (color) => {
        setFieldValue(name, color.hex);
        setDisplayValue(color.hex);
    };

    const colorBox = (
        <Input
            value={displayValue}
            onClick={() => { setShowPicker(true); }}
            onChange={handleChange}
            style={{ backgroundColor: `${value}` }}
        />
    );

    const picker = (
        <>
            <div
                style={modalOutlineStyles}
                role="dialog"
                onClick={handleClose}
                onKeyDown={handleClose}
            />
            <div
                style={pickerWrapperStyles}
            >
                <Picker
                    style={pickerStyles}
                    color={displayValue || '#FFF'}
                    onChange={handleChange}
                />
            </div>
        </>
    );

    return (
        <FieldWrapper
            name={name}
            required={required}
            helpText={help}
            label={label}
        >
            {colorBox}
            {showPicker && picker}
        </FieldWrapper>
    );
};

const messages = defineMessages({
    required: {
        id: 'colorpicker-required',
        defaultMessage: 'This field is required.',
    },
});

ColorPicker.getValidationSchema = (field: { validation: Object }) => {
    if (field.validation.required) {
        return Yup.string().required(messages.required).nullable();
    }

    return Yup.string().nullable();
};

ColorPicker.defaultProps = {
    type: 'full',
};

export default ColorPicker;
