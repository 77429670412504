// @flow
import Resource from 'speed-js-core/src/api/Resource';
import NomenclatureItemResource from './NomenclatureItemResource';

export default class NomenclatureTypeResource extends Resource {
    basePath: string = 'nomenclatures/types/';

    objectPath: string = 'nomenclatures/types/{ident}/';

    nestedResources = {
        items: NomenclatureItemResource,
    };

    items: NomenclatureItemResource
}
