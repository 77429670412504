// @flow
import Resource from 'speed-js-core/src/api/Resource';

export default class ServiceClientAuditEventsResource extends Resource {
    basePath: string = 'services/clientAuditEvents/';

    objectPath: string = 'services/clientAuditEvents/{ident}/';

    /**
     * Get the timeline information about a specific object
     *
     * @param {string} objectId - id of the requested object
     * @param {string} contentType - type of the object
     * @param {string} appLabel - label of the app where the contentType is found
     * @param {number} offset - pagination offset
     * @param {number} limit - pagination limit
     * @param {number} query - Object for additional query parameters
     * @returns {Promise<{ok: boolean, status: number, statusText: string, headers: Object, data: Object}>} API response
     */
    timeline(
        objectId: string, contentType: string, appLabel: string, offset: number, limit: number, query: Object,
    ): Promise<{}> {
        return this.client._request(
            `${this.basePath}${appLabel}/${contentType}/${objectId}/`, 'GET',
            { offset, limit, ...query },
        );
    }
}
