// @flow
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';

type Props = {
    day: React.Element,
    month: React.Element,
    year: React.Element,
    monthChoices?: Array,
    setValue?: Function,
    value: string,
    min_year: number,
    max_year: number,
}

const messages = defineMessages({
    year: {
        id: 'form.datepicker.label.year',
        defaultMessage: 'Year',
    },
    month: {
        id: 'form.datepicker.label.month',
        defaultMessage: 'Month',
    },
    day: {
        id: 'form.datepicker.label.day',
        defaultMessage: 'Day',
    },
});

const months = ['January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const thisYear = +(new Date().getFullYear());
const thisMonth = +(new Date().getMonth()) + 1;

// gets the number of days in the chosen month
const getMonthDays = (m = thisMonth, y = thisYear) => {
    const monthsWith30Days = [4, 6, 9, 11];
    const leapYear = (y % 4 === 0);
    if (Number(m) + 1 === 2) {
        if (leapYear) {
            return 29;
        }
        return 28;
    }
    if (monthsWith30Days.includes(Number(m) + 1)) {
        return 30;
    }
    return 31;
};

const DatePicker = (props: Props) => {
    const {
        day: BaseSelectDay, month: BaseSelectMonth, year: BaseSelectYear, monthChoices, setValue, value,
        min_year: minYear, max_year: maxYear,
    } = props;
    const { formatMessage } = useIntl();
    const [day, setDay] = React.useState(null);
    const [month, setMonth] = React.useState(null);
    const [year, setYear] = React.useState(null);

    const yearLowerBoundary = minYear || 1900;
    const yearUpperBoundary = maxYear || Number(thisYear) + 100;

    const years = [];
    for (let i = yearLowerBoundary; i <= yearUpperBoundary; i += 1) {
        years.unshift(i);
    }
    const totalYears = years.length;

    const getDates = React.useCallback(() => {
        const days = [];

        for (let i = 1; i <= getMonthDays(month, years[year]); i += 1) {
            days.push(i);
        }

        return days;
    }, [month, year]);

    const [totalDays, setTotalDays] = React.useState(getDates());

    // create an array of days for the chosen month and year
    const handleNumberOfDays = React.useCallback(() => {
        if (!month) return;

        setTotalDays(getDates());
    }, [month, year]);

    // set the year, month and day from the initial value or if there is no initial to the current date.
    React.useEffect(() => {
        setYear(value
            ? totalYears - (Number(value.split('-')[0]) - yearLowerBoundary + 1)
            : null);
        setMonth(value ? Number(value.split('-')[1]) - 1 : null);
        setDay(value ? (Number(value.split('-')[2]) - 1) : null);
        setValue(value || null);
    }, []);

    React.useEffect(() => {
        handleNumberOfDays();
    }, [handleNumberOfDays]);

    return (
        <>
            <BaseSelectYear
                label={formatMessage(messages.year)}
                onChange={(v) => {
                    setYear(v);
                    if (v && month && day) {
                        if (setValue) { setValue(`${years[v]}-${+month + 1}-${+day + 1}`); }
                    } else {
                        setMonth(null);
                        setDay(null);
                        if (setValue) {
                            setValue(null);
                        }
                    }
                }}
                choices={years}
                value={year}
            />
            <BaseSelectMonth
                label={formatMessage(messages.month)}
                onChange={(v) => {
                    setMonth(v);
                    if (v && year && day) {
                        if (setValue) { setValue(`${years[year]}-${+v + 1}-${+day + 1}`); }
                    } else {
                        setDay(null);
                        if (setValue) {
                            setValue(null);
                        }
                    }
                }}
                choices={monthChoices}
                value={month}
                disabled={!year && !value}
            />
            <BaseSelectDay
                label={formatMessage(messages.day)}
                onChange={(v) => {
                    setDay(v);
                    if (v && year && month) {
                        if (setValue) { setValue(`${years[year]}-${+month + 1}-${+v + 1}`); }
                    } else if (setValue) { setValue(null); }
                }}
                choices={totalDays}
                value={day}
                disabled={(!year || !month) && !value}
            />
        </>
    );
};

DatePicker.defaultProps = {
    monthChoices: months,
    setValue: null,
};

export default DatePicker;
