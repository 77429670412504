// @flow
import * as React from 'react';
import {
    memoize, isEmpty, isObject, get, set,
} from 'lodash';
import { Formik, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import memoizeOne from 'memoize-one';
import type { SchemaFormProps, SchemaType } from './types';


let FIELD_TYPES = {};
let FALLBACK_FIELD_TYPE;

type Props = SchemaFormProps & {
    initial: Object,
    children: (FormikProps: Object, fields: Object, form: SchemaForm) => React.Node,
}


class SchemaForm extends React.Component<Props> {
    parseSchema: (Object) => Object;

    static defaultProps = {
        initial: undefined,
        onSubmit: undefined,
        onChange: undefined,
        onReset: undefined,
    };

    static parseSchema(schema: SchemaType, prefix: string = ''): Object {
        const { fields } = schema;
        const result = {};
        const validationShape = {};
        fields.forEach((schemaField) => {
            const [key, value] = schemaField;
            const {
                type, read_only: readOnly, ui: { widget, ...ui }, validation,
            } = value;

            if (readOnly) return;

            const Component = FIELD_TYPES[widget || type] || FIELD_TYPES[FALLBACK_FIELD_TYPE];

            const fieldName = typeof Component.getFieldName !== 'undefined' ? Component.getFieldName(key) : key;

            result[fieldName] = (
                <Field name={`${prefix}${fieldName}`} key={key}>
                    {
                        (field: FieldProps) => (
                            <Component {...field} {...ui} {...validation} />
                        )
                    }
                </Field>
            );
            set(validationShape, fieldName, Component.getValidationSchema(value));
        });

        // Think of making it recursive
        Object.keys(validationShape).forEach((fieldName) => {
            const shape = validationShape[fieldName];

            if (isObject(shape) && !shape.__isYupSchema__) {
                validationShape[fieldName] = Yup.object().shape(shape);
            }
        });

        return [result, Yup.object().shape(validationShape)];
    }

    static registerFieldTypes(fields: {[string]: React.ComponentType<>}, fallbackType?: string) {
        FIELD_TYPES = {
            ...FIELD_TYPES,
            ...fields,
        };
        if (typeof fallbackType !== 'undefined') {
            FALLBACK_FIELD_TYPE = fallbackType;
        }
    }

    constructor(props: Props) {
        super(props);

        this.parseSchema = memoizeOne(SchemaForm.parseSchema);
        this.getInitialValues = memoize(this.getInitialValues);
    }

    getInitialValues = (initial?: Object, schema: SchemaType) => {
        // Formik requires initial values to be specified in order to set the touched flag to all fields on submit
        // https://github.com/jaredpalmer/formik/issues/445#issuecomment-366952762
        if (!initial || isEmpty(initial)) {
            const result = {};
            const { fields } = schema;

            fields.forEach((schemaField) => {
                const [name, value] = schemaField;
                if (!value.read_only) {
                    const {
                        type, ui: { widget }, initial: fieldInitial,
                    } = value;
                    const Component = FIELD_TYPES[widget || type] || FIELD_TYPES[FALLBACK_FIELD_TYPE];

                    const fieldName = typeof Component.getFieldName !== 'undefined'
                        ? Component.getFieldName(name) : name;

                    if (typeof Component.getEmptyInitialValues !== 'undefined') {
                        set(result, fieldName, Component.getEmptyInitialValues(value));
                    } else {
                        set(
                            result, fieldName,
                            typeof fieldInitial === 'undefined' || fieldInitial === ''
                                ? null : fieldInitial,
                        );
                    }
                }
            });
            return result;
        }
        const result = { ...initial };

        const { fields } = schema;
        fields.forEach((schemaField) => {
            const [name, value] = schemaField;
            if (!value.read_only) {
                let _v;

                const { type, ui: { widget } } = value;
                const Component = FIELD_TYPES[widget || type] || FIELD_TYPES[FALLBACK_FIELD_TYPE];

                const fieldName = typeof Component.getFieldName !== 'undefined'
                    ? Component.getFieldName(name) : name;

                if (value.ui.value_accessor && isObject(initial[name])) {
                    _v = get(initial[name], value.ui.value_accessor);
                    set(result, fieldName, _v);
                } else {
                    if (typeof result[name] === 'undefined') {
                        _v = value.initial;
                    }
                    if (!(fieldName in result)) {
                        set(result, fieldName, _v);
                    }
                }
            }
        });
        return result;
    };

    render() {
        const {
            schema, initial, onSubmit, onReset, children, formikRef, enableReinitialize, formValues, ...rest
        } = this.props;

        let initialValues = initial;
        if (enableReinitialize) {
            initialValues = { ...formValues, ...initial };
        }
        initialValues = this.getInitialValues(initialValues, schema);

        const [fields, validationSchema] = this.parseSchema(schema);

        return (
            <Formik
                enableReinitialize={enableReinitialize}
                ref={formikRef}
                onSubmit={onSubmit}
                onReset={onReset}
                initialValues={initialValues}
                validationSchema={validationSchema}
                schema={schema}
                {...rest}
            >
                {
                    (props: Object) => children(props, fields, this)
                }
            </Formik>
        );
    }
}

export default SchemaForm;
