// @flow

import * as React from 'react';
import { Col, Row } from 'reactstrap';
import DatePickerWithMonths from 'speed-js-react-web/src/components/fields/DatePickerWithMonths';
import TimePicker from 'speed-js-react/src/components/timepicker/TimePicker';
import styled from 'styled-components';
import * as Yup from 'yup';
import DatePickerWithWrapper from './DatePickerWithWrapper';
import NumberInputTime from './NumberInputTime';
import FieldWrapper from './FieldWrapper';

type Props = {
    setValue: Function,
    value: string,
};

const RowNoMargin = styled(Row)`
    margin: 0;
`;

const DateTimePicker = (props: Props) => {
    const {
        setValue, value,
    } = props;

    const addZero = (v) => {
        const numberV = Number(v);
        if (numberV < 10) {
            return `0${numberV}`;
        }
        return v;
    };

    const getDateFromValue = () => {
        try {
            const valueDate = new Date(value);
            const year = valueDate.getFullYear();
            const month = addZero(valueDate.getMonth() + 1);
            const day = addZero(valueDate.getDate());
            return `${year}-${month}-${day}`;
        } catch (e) {
            return null;
        }
    };

    const getTimeFromValue = () => {
        try {
            const valueDate = new Date(value);
            const hours = addZero(valueDate.getHours());
            const minutes = addZero(valueDate.getMinutes());
            return `${hours}:${minutes}`;
        } catch (e) {
            return '00:00';
        }
    };

    const [date, setDate] = React.useState(value ? getDateFromValue() : null);
    const [time, setTime] = React.useState(value ? getTimeFromValue() : '');
    const hourValue = time ? time.split(':')[0] : '';
    const minuteValue = time ? time.split(':')[1] : '';

    const handleInputChange = (inputType, inputValue) => {
        try {
            let newDate;
            if (inputValue) {
                if (inputType === 'date') {
                    newDate = `${inputValue}T${time}`;
                } else if (inputType === 'time') {
                    if (date) {
                        newDate = `${date}T${inputValue}`;
                    }
                }
            }
            if (newDate) {
                const newDateIso = (new Date(newDate)).toISOString();
                setValue(newDateIso);
            } else {
                setValue(null);
            }
        } catch (e) {
            console.error(e);
            setValue(null);
        }
    };

    const formatDateString = (dateInput) => {
        try {
            if (dateInput) {
                const dateSplitted = dateInput.split('-');
                return `${dateSplitted[0]}-${addZero(dateSplitted[1])}-${addZero(dateSplitted[2])}`;
            }
            return null;
        } catch (e) {
            return null;
        }
    };

    const handleDateInputChange = (v) => {
        const formattedDate = formatDateString(v);
        setDate(formattedDate);
        handleInputChange('date', formattedDate);
    };

    const formatTimeString = (timeInput) => {
        try {
            if (timeInput) {
                const timeSplitted = timeInput.split(':');
                return `${addZero(timeSplitted[0])}:${addZero(timeSplitted[1])}`;
            }
            return '00:00';
        } catch (e) {
            return '00:00';
        }
    };

    const handleTimeInputChange = (e, v) => {
        const formattedTime = formatTimeString(v);
        setTime(formattedTime);
        handleInputChange('time', formattedTime);
    };

    return (
        <>
            <RowNoMargin>
                <DatePickerWithMonths
                    {...props}
                    day={DatePickerWithWrapper}
                    month={DatePickerWithWrapper}
                    year={DatePickerWithWrapper}
                    setValue={handleDateInputChange}
                    value={date}
                />
            </RowNoMargin>
            <TimePicker
                hour={NumberInputTime}
                minute={NumberInputTime}
                hourValue={hourValue}
                minuteValue={minuteValue}
                time={time}
                wrapper={FieldWrapper}
                onChange={handleTimeInputChange}
                field={{
                    value: time,
                }}
                required
                col={Col}
                row={Row}
            />
        </>
    );
};

DateTimePicker.getValidationSchema = (field: { validation: Object }) => Yup.string().nullable();

export default DateTimePicker;
