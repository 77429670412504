// @flow

import * as React from 'react';
import { Col } from 'reactstrap';

const widths = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

const Colxx = (props) => (
    <Col {...props} widths={widths} />
);

export default Colxx;
