// @flow

import Resource from 'speed-js-core/src/api/NestedResource';

export default class ParkingMiddlewareResource extends Resource {
    basePath: string = 'parkings/parkings/{parent}/middleware_request/';

    objectPath: string = 'parkings/parkings/{parent}/middleware_request/{ident}/';

    get(parentId: string, id: string, query: Object): Promise<Object> {
        query.id = id;
        return this.client._request(this._getBasePath(parentId), 'GET', query).then((response) => response.data);
    }

    update(parentId: string, id: string, data: Object): Promise<Object> {
        data.id = id;
        return this.client._request(this._getBasePath(parentId), 'PUT', data).then((response) => response.data);
    }

    partialUpdate(parentId: string, id: string, data: Object): Promise<Object> {
        data.id = id;
        return this.client._request(this._getBasePath(parentId), 'PATCH', data).then((response) => response.data);
    }

    delete(parentId: string, id: string, query: Object): Promise<Object> {
        query.id = id;
        return this.client._request(this._getBasePath(parentId), 'DELETE', query)
            .then((response) => response.data);
    }

    cameras(parentId: string, query: Object): Promise<Object> {
        return this.client._request(
            `parkings/parkings/${parentId}/cameras/`, 'GET', query,
        ).then((response) => response.data);
    }
}
