// @flow
import * as React from 'react';
import { Input, Label } from 'reactstrap';
import * as Yup from 'yup';
import {
    defineMessages,
} from 'react-intl';

type Props = {
    onChange: Function,
    value: string,
    label: string,
    onBlur: Function,
    name: string,
}

const NumberInputTime = (props: Props): React.Node => {
    const {
        value,
        onChange,
        onBlur,
        label,
        name,
    } = props;

    return (
        <div>
            {label ? <Label>{label}</Label> : null}
            <Input
                onChange={onChange}
                onBlur={onBlur}
                placeholder={label}
                value={value}
                name={name}
                type="number"
            />
        </div>
    );
};


const messages = defineMessages({
    required: {
        id: 'numberinput-required',
        defaultMessage: 'This field is required.',
    },
    min: {
        id: 'numberinput-min',
        defaultMessage: 'The value must be greater than {minValue}!',
    },
    max: {
        id: 'numberinput-max',
        defaultMessage: 'The value must be less than {maxValue}!',
    },
});

NumberInputTime.getValidationSchema = (field: { validation: Object }) => {
    let errMsg = Yup.number().nullable();
    if (field.validation.required) {
        errMsg = Yup.number().required(messages.required).nullable();
    }
    if (field.validation.min) {
        errMsg = errMsg.min(field.validation.min.toString(),
            { ...messages.min, values: { minValue: field.validation.min.toString() } });
    }
    if (field.validation.max) {
        errMsg = errMsg.max(field.validation.max.toString(),
            { ...messages.max, values: { maxValue: field.validation.max.toString() } });
    }

    return errMsg;
};

export default NumberInputTime;
