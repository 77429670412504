// @flow

import type ApiClient from 'speed-js-core/src/api/ApiClient';
import type NestedResource from 'speed-js-core/src/api/NestedResource';

export default class GenericRelationResource {
    client: ApiClient;

    basePath: string; // Example: 'attachments/{appLabel}/{contentType}/{objectId}/'

    objectPath: string; // Example: 'attachments/{appLabel}/{contentType}/{objectId}/{ident}/'

    nestedResources: { [string]: Class<NestedResource> };

    constructor(client: ApiClient) {
        this.client = client;
    }

    setNestedResources() {
        if (this.nestedResources) {
            Object.keys(this.nestedResources).forEach((sr) => {
                // $FlowFixMe
                this[sr] = new this.nestedResources[sr](this.client);
            });
        }
    }

    _getBasePath = (appLabel: string, contentType: string, objectId: string) => this.basePath
        .replace('{appLabel}', appLabel)
        .replace('{contentType}', contentType)
        .replace('{objectId}', objectId);

    _getObjectPath = (appLabel: string, contentType: string, objectId: string,
        id: string) => this.objectPath
        .replace('{appLabel}', appLabel)
        .replace('{contentType}', contentType)
        .replace('{objectId}', objectId)
        .replace('{ident}', id);

    schema(appLabel: string, contentType: string, objectId: string): Promise<Object> {
        return this.client._request(this._getBasePath(appLabel, contentType, objectId), 'OPTIONS')
            .then((response) => response.data);
    }

    schemaMethod(appLabel: string, contentType: string, objectId: string, method: string): Promise<Object> {
        return this.client._request(`${this._getBasePath(appLabel, contentType, objectId)}${method}/`, 'OPTIONS')
            .then((response) => response.data);
    }

    list(appLabel: string, contentType: string, objectId: string, query: Object): Promise<Object> {
        return this.client._request(this._getBasePath(appLabel, contentType, objectId), 'GET', query)
            .then((response) => response.data);
    }

    get(appLabel: string, contentType: string, objectId: string, id: string, query: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(appLabel, contentType, objectId, id), 'GET', query)
            .then((response) => response.data);
    }

    create(appLabel: string, contentType: string, objectId: string, data: Object, files: Object): Promise<Object> {
        return this.client._request(this._getBasePath(appLabel, contentType, objectId), 'POST', data, null, files)
            .then((response) => response.data);
    }

    update(appLabel: string, contentType: string, objectId: string, id: string, data: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(appLabel, contentType, objectId, id), 'PUT', data)
            .then((response) => response.data);
    }

    partialUpdate(appLabel: string, contentType: string, objectId: string, id: string, data: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(appLabel, contentType, objectId, id), 'PATCH', data)
            .then((response) => response.data);
    }

    delete(appLabel: string, contentType: string, objectId: string, id: string): Promise<Object> {
        return this.client._request(this._getObjectPath(appLabel, contentType, objectId, id), 'DELETE')
            .then((response) => response.data);
    }
}
