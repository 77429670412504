// @flow

import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { get } from 'lodash';
import TimePicker from 'speed-js-react/src/components/timepicker/TimePicker';
import * as Yup from 'yup';
import {
    defineMessages,
} from 'react-intl';
import NumberInputTime from './NumberInputTime';
import FieldWrapper from './FieldWrapper';

type Props = {
    field: {
        onChange: Function,
        onBlur: Function,
        value: any,
        name: string,
    },
    form: {
        setFieldTouched: Function,
        setFieldValue: Function,
        errors: string,
    },
    label: string,
    required: boolean,
};

const TimePickerInput = (props: Props) => {
    const {
        field, form, label, required,
    } = props;

    const [time, setTime] = React.useState(field.value ? field.value : '');

    const handleInputChange = (e, v) => {
        const formattedTime = formatTimeString(v);
        field.onChange(e);
        field.onBlur(formattedTime);
        form.setFieldValue(field.name, formattedTime);
        form.setFieldTouched(field.name, true);
        setTime(formattedTime);
    };

    const addZero = (v) => {
        const numberV = Number(v);
        if (numberV < 10) {
            return `0${numberV}`;
        }
        return v;
    };

    const formatTimeString = (timeInput) => {
        try {
            if (timeInput) {
                const timeSplitted = timeInput.split(':');
                return `${addZero(timeSplitted[0])}:${addZero(timeSplitted[1])}`;
            }
            return '00:00';
        } catch (e) {
            return '00:00';
        }
    };

    const hourValue = time ? time.split(':')[0] : '';
    const minuteValue = time ? time.split(':')[1] : '';

    return (
        <FieldWrapper
            label={label}
            name={field.name}
            required={required}
        >
            <TimePicker
                hour={NumberInputTime}
                minute={NumberInputTime}
                hourValue={hourValue}
                minuteValue={minuteValue}
                time={time}
                wrapper={FieldWrapper}
                onChange={handleInputChange}
                onBlur={field.onBlur}
                field={{
                    ...field,
                    value: time,
                }}
                errors={get(form.errors, field.name)}
                required
                col={Col}
                row={Row}
            />
        </FieldWrapper>
    );
};


const messages = defineMessages({
    required: {
        id: 'timeinput-required',
        defaultMessage: 'This field is required.',
    },
    wrongTime: {
        id: 'timeinput-wrong-hours',
        defaultMessage: 'Please enter valid time!',
    },
});

TimePickerInput.getValidationSchema = (field: { validation: Object }) => {
    let errMsg = Yup.string().nullable();
    const hoursRegex = '^([0-1]?[0-9]|2[0-3]):';
    const minutesRegex = ':[0-5][0-9]';

    errMsg = errMsg.matches(hoursRegex, messages.wrongTime)
        .matches(minutesRegex, messages.wrongTime);

    return errMsg;
};

export default TimePickerInput;
