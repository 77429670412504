// @flow

import Resource from 'speed-js-core/src/api/NestedResource';

export default class ServiceResource extends Resource {
    basePath: string = 'clients/clients/{parent}/services/';

    objectPath: string = 'clients/clients/{parent}/services/{ident}/';

    baseSTPath: string = 'clients/clients/{parent}/services/type/{serviceType}/';

    objectSTPath: string = 'clients/clients/{parent}/services/type/{serviceType}/{ident}/';

    _getBasePath = (parentId: string, serviceTypeId?: string) => (
        serviceTypeId
            ? this.baseSTPath.replace(
                '{parent}', parentId,
            ).replace('{serviceType}', serviceTypeId)
            : this.basePath.replace('{parent}', parentId)
    );

    _getObjectPath = (parentId: string, serviceTypeId?: string, id: string) => (
        serviceTypeId
            ? this.objectSTPath.replace(
                '{ident}', id,
            ).replace('{serviceType}', serviceTypeId).replace('{parent}', parentId)
            : this.objectPath.replace('{ident}', id).replace('{parent}', parentId)
    );

    schema(parentId: string, serviceTypeId?: string, query: Object): Promise<Object> {
        return this.client._request(
            this._getBasePath(parentId, serviceTypeId), 'OPTIONS', query,
        ).then((response) => response.data);
    }

    list(parentId: string, serviceTypeId?: string, query: Object): Promise<Object> {
        return this.client._request(
            this._getBasePath(parentId, serviceTypeId), 'GET', query,
        ).then((response) => response.data);
    }

    get(parentId: string, serviceTypeId?: string, id: string, query: Object): Promise<Object> {
        return this.client._request(
            this._getObjectPath(parentId, serviceTypeId, id), 'GET', query,
        ).then((response) => response.data);
    }

    create(parentId: string, serviceTypeId?: string, data: Object): Promise<Object> {
        return this.client._request(
            this._getBasePath(parentId, serviceTypeId), 'POST', data,
        ).then((response) => response.data);
    }

    update(parentId: string, serviceTypeId?: string, id: string, data: Object): Promise<Object> {
        return this.client._request(
            this._getObjectPath(parentId, serviceTypeId, id), 'PUT', data,
        ).then((response) => response.data);
    }

    partialUpdate(parentId: string, serviceTypeId?: string, id: string, data: Object): Promise<Object> {
        return this.client._request(
            this._getObjectPath(parentId, serviceTypeId, id), 'PATCH', data,
        ).then((response) => response.data);
    }

    delete(parentId: string, serviceTypeId?: string, id: string): Promise<Object> {
        return this.client._request(
            this._getObjectPath(parentId, serviceTypeId, id), 'DELETE',
        ).then((response) => response.data);
    }
}
