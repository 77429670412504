// @flow
import * as React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import {
    defineMessages,
} from 'react-intl';
import FieldWrapper from './FieldWrapper';


type Props = {
    field: {
        onChange: (e: SyntheticInputEvent<number>) => void,
        onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
        onBlur: (e: SyntheticFocusEvent<any>) => void,
        value: any;
        name: string;
    },
    form: {
        errors: Object,
        touched: Object,
        isSubmitting: boolean,
    },
    required: boolean,
    label: string,
    help_text: string,
    disabled?: boolean,
}


const TextInput = (props: Props): React.Node => {
    const {
        disabled,
        field: {
            name, onChange, onBlur, value,
        },
        form: { errors, touched, isSubmitting },
        label,
        help_text: help,
        required,
    } = props;

    const errs = get(errors, name);

    return (
        <FieldWrapper
            name={name}
            required={required}
            helpText={help}
            label={label}
        >
            <Field
                className={errs ? 'form-control is-invalid' : 'form-control'}
                name={name}
                onChange={onChange}
                placeholder={label}
                onBlur={onBlur}
                value={value || ''}
                id={`id-${name}`}
                disabled={disabled || isSubmitting}
            />
        </FieldWrapper>
    );
};


const messages = defineMessages({
    min: {
        id: 'textinput-min',
        defaultMessage: 'The length of this field must be more than {minLimit} characters!',
    },
    max: {
        id: 'textinput-max',
        defaultMessage: 'The length of this field must be less than {maxLimit} characters!',
    },
    required: {
        id: 'textinput-required',
        defaultMessage: 'This field is required.',
    },
});

TextInput.getValidationSchema = (field: { validation: Object }) => {
    let errMsg = Yup.string().nullable();
    if (field.validation.required) {
        errMsg = Yup.string().required(messages.required).nullable();
    }
    if (field.validation.min_length) {
        errMsg = errMsg.min(field.validation.min_length.toString(),
            { ...messages.min, values: { minLimit: field.validation.min_length.toString() } });
    }
    if (field.validation.max_length) {
        errMsg = errMsg.max(field.validation.max_length.toString(),
            { ...messages.max, values: { maxLimit: field.validation.max_length.toString() } });
    }

    return errMsg;
};

TextInput.defaultProps = {
    disabled: false,
};

export default TextInput;
