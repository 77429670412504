// @flow

import Resource from 'speed-js-core/src/api/Resource';

export default class ActiveCasesResource extends Resource {
    basePath: string = 'cases/activeCases/';

    objectPath: string = 'cases/activeCases/{ident}/';

    getData(): Promise<Object> {
        return this.client._request(`${this._getBasePath()}`, 'GET')
            .then((response) => response);
    }
}
