// @flow
import * as React from 'react';
import {
    FormGroup, Label, FormText,
} from 'reactstrap';
import Colxx from 'speed-react-gogo-tmpl/src/components/common/Colxx';
import RequiredAsterisk from '../RequiredAsterisk';

type Props = {
    name?: string;
    children: React.Node,
    required?: boolean,
    label: string,
    helpText?: string,
}


const DatePickerWrapper = (props: Props): React.Node => {
    const {
        name, label, helpText, required, children,
    } = props;

    return (
        <Colxx sm="4">
            <FormGroup>
                {label && (
                    <Label as="label" htmlFor={`id-${name}`}>
                        {label}
                        {required ? <RequiredAsterisk /> : null}
                    </Label>
                )}
                {children}
                {helpText && (
                    <FormText color="muted">{helpText}</FormText>
                )}
            </FormGroup>
        </Colxx>
    );
};

DatePickerWrapper.defaultProps = {
    name: '',
    helpText: '',
    required: false,
};

export default DatePickerWrapper;
