// @flow

import Resource from 'speed-js-core/src/api/Resource';
import ContactDetailResource from './ContactDetailResource';

export default class EntityResource extends Resource {
    basePath: string = 'entities/entities/';

    objectPath: string = 'entities/entities/{ident}/';

    nestedResources = {
        contactDetails: ContactDetailResource,
    };

    contactDetails: ContactDetailResource;
}
