// @flow

import Resource from 'speed-js-core/src/api/NestedResource';

export default class CasePatrolResource extends Resource {
    basePath: string = 'cases/cases/{parent}/patrols/';

    objectPath: string = 'cases/cases/{parent}/patrols/{ident}/';

    dispatch(parentId: string, casePatrolId: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(parentId, casePatrolId)}dispatch-patrol/`, 'PATCH',
        ).then((response) => response.data);
    }

    accept(parentId: string, casePatrolId: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(parentId, casePatrolId)}accept/`, 'PATCH',
        ).then((response) => response.data);
    }

    reject(parentId: string, casePatrolId: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(parentId, casePatrolId)}reject/`, 'PATCH',
        ).then((response) => response.data);
    }

    arrivedOnSite(parentId: string, casePatrolId: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(parentId, casePatrolId)}arrived-on-site/`, 'PATCH',
        ).then((response) => response.data);
    }

    cancel(parentId: string, casePatrolId: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(parentId, casePatrolId)}cancel/`, 'PATCH',
        ).then((response) => response.data);
    }
}
