// @flow
import { handleActions } from 'redux-actions';
import {
    addBreadcrumb, addBreadcrumbs, removeBreadcrumb, setPageTitle,
} from './actions';


export type Breadcrumb = {
    +title: string,
    +link?: string,
};

export type State = {
    title: string | null,
    breadcrumbs: Array<Breadcrumb>,
};

const initialState: State = {
    title: null,
    breadcrumbs: [],
};

const reducer = handleActions({
    [addBreadcrumb]: (state: State, { payload }: {
        payload: {
            title: string,
            link?: string,
        }
    }) => {
        const { title, link } = payload;

        let newBreadcrumbs = [];
        const bIndex = state.breadcrumbs.findIndex((b) => b.title === title);
        if (bIndex !== -1) {
            newBreadcrumbs = state.breadcrumbs.slice(0, bIndex + 1);
        } else {
            newBreadcrumbs = state.breadcrumbs.slice(0);
            newBreadcrumbs.push({
                title, link,
            });
        }

        return {
            ...state,
            breadcrumbs: newBreadcrumbs,
        };
    },
    [addBreadcrumbs]: (state: State, { payload }: {
        payload: {
            breadcrumbs: Array<Breadcrumb>,
        }
    }) => {
        const { breadcrumbs } = payload;
        const firstBreadcrumb = breadcrumbs[0];

        let newBreadcrumbs = [];
        const bIndex = state.breadcrumbs.findIndex((b) => b.title === firstBreadcrumb.title);
        if (bIndex !== -1) {
            newBreadcrumbs = state.breadcrumbs.slice(0, bIndex);
        } else {
            newBreadcrumbs = state.breadcrumbs.slice(0);
        }

        return {
            ...state,
            breadcrumbs: [
                ...newBreadcrumbs, ...breadcrumbs,
            ],
        };
    },
    [removeBreadcrumb]: (state: State, { payload }: {
        payload: {
            title: string,
            offset: number
        },
    }) => {
        const { title } = payload;

        return {
            ...state,
            breadcrumbs: [
                ...state.breadcrumbs.filter((breadcrumb) => breadcrumb.title !== title),
            ],
        };
    },
    [setPageTitle]: (state: State, { payload }: {
        payload: string | null,
    }) => ({
        ...state,
        title: payload,
    }),
}, initialState);

export default reducer;
