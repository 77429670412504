// @flow

import Resource from 'speed-js-core/src/api/Resource';

export default class OpenCasesResource extends Resource {
    basePath: string = 'cases/alarms/open/';

    objectPath: string = 'cases/alarms/open/{ident}/';

    getData(): Promise<Object> {
        return this.client._request(`${this._getBasePath()}`, 'GET')
            .then((response) => response);
    }
}
