// @flow
import { get } from 'lodash';
import * as React from 'react';
import { CustomInput } from 'reactstrap';
import * as Yup from 'yup';
import { defineMessages } from 'react-intl';
import FieldWrapper from './FieldWrapper';
import RequiredAsterisk from '../RequiredAsterisk';


type Props = {
    field: {
        onChange: (e: SyntheticInputEvent<number>) => void,
        onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
        onBlur: (e: SyntheticFocusEvent<any>) => void,
        value: any;
        name: string;
    },
    form: {
        errors: Object,
    },
    required: boolean,
    label: string,
    help_text: string,
    disabled?: boolean,
}


const Checkbox = (props: Props): React.Node => {
    const {
        disabled,
        field: {
            name, onChange, onBlur, value,
        },
        label,
        form: { errors },
        help_text: helpText,
        required,
    } = props;

    const errs = get(errors, name);

    return (
        <FieldWrapper name={name} helpText={helpText}>
            <div className={errs ? 'is-invalid' : null}>
                <CustomInput
                    type="checkbox"
                    id={`id-${name}`}
                    name={name}
                    label={required ? (
                        <>
                            {label}
                            <RequiredAsterisk />
                        </>
                    ) : label}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value || false}
                    disabled={disabled}
                    inline
                />
            </div>
        </FieldWrapper>
    );
};

const messages = defineMessages({
    required: {
        id: 'checkbox-required',
        defaultMessage: 'This field is required.',
    },
});

Checkbox.getValidationSchema = (field: { validation: Object }) => {
    if (field.validation.required) {
        return Yup.string().required(messages.required).nullable();
    }

    return Yup.string().nullable();
};

Checkbox.getEmptyInitialValues = () => false;
Checkbox.defaultProps = {
    disabled: false,
};

export default Checkbox;
