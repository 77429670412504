// @flow
import * as React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import {
    defineMessages,
} from 'react-intl';
import FieldWrapper from './FieldWrapper';


type Props = {
    field: {
        onChange: (e: SyntheticInputEvent<number>) => void,
        onFocus: (e: SyntheticFocusEvent<HTLMInputElement>) => void,
        onBlur: (e: SyntheticFocusEvent<any>) => void,
        value: any;
        name: string;
    },
    required: boolean,
    label: string,
    help_text: string,
    form: {
        setFieldValue: (string, any) => void,
        setFieldError: (string, any) => void,
        touched: Object,
        errors: Object,
        isSubmitting: boolean,
    },
    disabled?: boolean
}

// eslint-disable-next-line require-jsdoc
const JSONInput = (props: Props): React.Node => {
    const {
        disabled,
        field: {
            name, onBlur, value,
        },
        label,
        help_text: help,
        required,
        form: {
            errors, isSubmitting, setFieldValue, setFieldError,
        },
    } = props;


    const errs = get(errors, name);

    const handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
        try {
            setFieldValue(name, JSON.parse(e.target.value));
        } catch (err) {
            setFieldValue(name, e.target.value);
            setFieldError(name, 'Invalid json');
        }
    };

    let _val = value;

    if (_val && typeof _val !== 'string') {
        _val = JSON.stringify(value);
    }

    return (
        <FieldWrapper
            name={name}
            required={required}
            helpText={help}
            label={label}
        >
            <Field
                className={errs ? 'form-control is-invalid' : 'form-control'}
                name={name}
                onChange={handleChange}
                placeholder={label}
                onBlur={onBlur}
                value={_val || ''}
                id={`id-${name}`}
                disabled={disabled || isSubmitting}
            />
        </FieldWrapper>
    );
};

const messages = defineMessages({
    required: {
        id: 'jsoninput-required',
        defaultMessage: 'This field is required.',
    },
});

JSONInput.getValidationSchema = (field: { validation: Object }) => {
    let errMsg = Yup.string().nullable();
    if (field.validation.required) {
        errMsg = Yup.string().required(messages.required).nullable();
    }

    return errMsg;
};

JSONInput.defaultProps = {
    disabled: false,
};

export default JSONInput;
