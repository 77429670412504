// @flow

import Resource from 'speed-js-core/src/api/Resource';

import AvailablePatrolsResource from './AvailablePatrolsResource';
import CasePatrolResource from './CasePatrolResource';

export default class CasesResource extends Resource {
    basePath: string = 'cases/cases/';

    objectPath: string = 'cases/cases/{ident}/';

    nestedResources = {
        availablePatrols: AvailablePatrolsResource,
        patrols: CasePatrolResource,
    };

    take(id: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(id)}take/`, 'PATCH', {},
        ).then((response) => response.data);
    }

    release(id: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(id)}release/`, 'PATCH', {},
        ).then((response) => response.data);
    }

    process(id: string, data: Object): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(id)}process/`, 'PATCH', data,
        ).then((response) => response.data);
    }

    opened(): Promise<Object> {
        return this.client._request(
            `${this._getBasePath()}opened/`, 'GET',
        ).then((response) => response.data);
    }

    setClientVisibility(id: string, data: Object): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(id)}set_client_visibility/`, 'PATCH', data,
        ).then((response) => response.data);
    }

    setFloorPlanVisibility(id: string, data: Object): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(id)}set_floor_plan_visibility/`, 'PATCH', data,
        ).then((response) => response.data);
    }

    setHiddenPicturesVisibility(id: string, data: Object): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(id)}set_hidden_pictures_visibility/`, 'PATCH', data,
        ).then((response) => response.data);
    }

    integrationData(id: string, query: Object): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(id)}integration-data/`, 'GET', query,
        ).then((response) => response.data);
    }

    manualStatusChange(id: string, data: Object): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(id)}manual_status_change/`, 'PATCH', data,
        ).then((response) => response.data);
    }

    seen(id: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(id)}seen/`, 'PATCH', {},
        ).then((response) => response.data);
    }
}
