// @flow
import * as React from 'react';
import * as Yup from 'yup';
import {
    defineMessages, useIntl,
} from 'react-intl';
import { useDropzone } from 'react-dropzone';
import FileComponent from './FileComponent';
import FieldWrapper from './FieldWrapper';

type Props = {
    field: {
        onChange: (e: SyntheticInputEvent<number>) => void,
        onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
        onBlur: (e: SyntheticFocusEvent<any>) => void,
        value: Object[];
        name: string;
    },
    form: {
        setFieldValue: (string, any) => void,
        setFieldTouched: (string, boolean, boolean) => void,
        errors: Object,
        touched: Object,
        isSubmitting: boolean,
    },
    required: boolean,
    label: string,
    help_text: string,
    disabled?: boolean,
    many: boolean,
    accept?: string,
}


const messages = defineMessages({
    required: {
        id: 'FileInput-required',
        defaultMessage: 'This field is required.',
    },
    dragAndDrop: {
        id: 'FileInput-dragAndDrop',
        defaultMessage: 'Drag and drop some files here, or click to select files',
    },
});


const FileInput = (props: Props): React.Node => {
    const { formatMessage } = useIntl();

    const {
        field: {
            name, value,
        },
        form: {
            setFieldValue, setFieldTouched, errors,
        },
        label,
        help_text: help,
        required,
        many,
        accept,
    } = props;

    const removeFile = React.useCallback((file) => {
        const newFiles = [...value];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFieldValue(name, newFiles);
        setFieldTouched(name, true, false);
    }, [value, setFieldValue, setFieldTouched, name]);

    const onDrop = React.useCallback((acceptedFiles) => {
        setFieldValue(name, acceptedFiles);
        setFieldTouched(name, true, false);
    }, [name, setFieldValue, setFieldTouched]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: many,
        accept,
    });

    const message = value && value.length > 0 ? (
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
            }}
        >
            {value.map((file) => (
                <FileComponent
                    key={file.name}
                    file={file}
                    removeFile={(e) => {
                        removeFile(file);
                        e.stopPropagation();
                    }}
                />
            ))}
        </div>
    ) : formatMessage(messages.dragAndDrop);

    const filePicker = (
        <div
            {...getRootProps()}
            className={errors ? 'dropzone is-invalid' : 'dropzone'}
        >
            <input {...getInputProps()} />
            <div>
                {isDragActive ? <p>Drop the files here ...</p> : (
                    <div>
                        {message}
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <FieldWrapper
            name={name}
            required={required}
            helpText={help}
            label={label}
        >
            {filePicker}
        </FieldWrapper>
    );
};


FileInput.getValidationSchema = (field: { validation: Object }) => {
    let errMsg = Yup.array();
    if (field.validation.required) {
        errMsg = errMsg.required(messages.required);
    }
    return errMsg.nullable();
};


FileInput.getFieldName = (name: string) => (`files.${name}`);


FileInput.defaultProps = {
    accept: '',
    disabled: false,
    many: true,
};

export default FileInput;
