// @flow
import { createAction } from 'redux-actions';
import {
    SET_USER, REMOVE_USER, SET_PERMISSIONS, RESET_AUTH_STATE,
} from './constants';
// eslint-disable-next-line
export const setUser = createAction(SET_USER);
export const removeUser = createAction(REMOVE_USER);
export const setPermissions = createAction(SET_PERMISSIONS);
export const resetAuthState = createAction(RESET_AUTH_STATE);
