// @flow
import Resource from 'speed-js-core/src/api/Resource';

export default class UserResource extends Resource {
    basePath: string = 'authorisation/users/';

    objectPath: string = 'authorisation/users/{ident}/';

    delete(id: string): Promise<Object> {
        if (id) {
            return this.client._request(this._getObjectPath(id), 'DELETE').then((response) => response.data);
        }
        // Delete current user
        return this.client._request(
            `${this._getBasePath()}delete`, 'DELETE',
        ).then((response) => response.data);
    }
}
