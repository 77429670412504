// @flow

import ApiClientError, { ERR_AUTH_BACKEND_NOT_INITIALIZED } from 'speed-js-core/src/api/errors';
import ApiClient from 'speed-js-core/src/api/ApiClient';
import type { WebsocketAuthBackend } from './WebsocketAuthBackend';
import WebSocketClient, { AUTH_MESSAGE } from '../WebSocketClient';

/**
 * A Websocket authentication backend that will send an "authenticate" ws message with the auth headers
 * from the given ApiClient instance;
 */
class ApiClientAuthBackend implements WebsocketAuthBackend {
    apiClient: ApiClient;

    _client: WebSocketClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;

        this.apiClient.subscribe('authorized', () => {
            this._client.reconnect();
            return Promise.resolve();
        });
    }

    setClient(client: WebSocketClient) {
        this._client = client;
    }

    authorize(): Promise<typeof undefined> {
        // Wait 0.1 second, max 1000 times, to check if the client is ready (auth backend is initialized)
        const send = async () => {
            const headers = await this.apiClient.auth.getHeaders();
            this._client.send(AUTH_MESSAGE, headers);
        };

        return new Promise((resolve) => {
            if (this.apiClient.ready) {
                send().then(resolve);
                return;
            }

            let retries = 1000;

            const wait = () => {
                if (retries > 0) {
                    setTimeout(() => {
                        if (this.apiClient.ready) {
                            send().then(resolve);
                            return;
                        }
                        retries -= 1;
                        wait();
                    }, 100);
                } else {
                    throw new ApiClientError(
                        'Auth backend not initialized',
                        ERR_AUTH_BACKEND_NOT_INITIALIZED,
                    );
                }
            };
            wait();
        });
    }
}

export default ApiClientAuthBackend;
