// @flow
import * as React from 'react';

type Props = {
    file: Object,
    removeFile: Function,
    rightMargin?: string,
    bottomMargin?: string,
}

const FileComponent = (props: Props) => {
    const {
        file,
        removeFile,
        rightMargin,
        bottomMargin,
        ...rest
    } = props;

    return (
        <div
            className="disable-text-selection"
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingLeft: 6,
                borderRadius: 2,
                backgroundColor: '#e6e6e6',
                boxSizing: 'border-box',
                color: '#333333',
                fontSize: 12,
                marginRight: rightMargin,
                marginBottom: bottomMargin,
            }}
            onClick={(e) => e.stopPropagation()}
            onKeyPress={() => {}}
            role="button"
            tabIndex="-1"
        >
            <div
                style={{
                    paddingTop: 3,
                    paddingBottom: 3,
                }}
            >
                {file.name}
            </div>
            <div
                style={{
                    display: 'flex',
                    marginLeft: 5,
                    paddingLeft: 4,
                    paddingRight: 4,
                    boxSizing: 'border-box',
                    alignItems: 'center',
                }}
                className="fileCloseButton"
                onClick={removeFile}
                onKeyPress={() => {}}
                role="button"
                tabIndex="-1"
            >
                <svg
                    viewBox="0 0 20 20"
                    className="fileCloseButtonIcon"
                >
                    <path
                        className="fileCloseButtonIconPath"
                        d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651
                        3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229
                        0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469
                        1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0
                        1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                    />
                </svg>
            </div>
        </div>
    );
};

FileComponent.defaultProps = {
    rightMargin: 5,
    bottomMargin: 10,
};

export default FileComponent;
