// @flow
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import type { ValueType } from 'react-select/src/types';
import BaseSelect from 'speed-js-react-web/src/components/fields/BaseSelect';
import * as Yup from 'yup';
import { get } from 'lodash';
import { triggerNativeEvent } from 'speed-js-react-web/src/utils/events';
import FieldWrapper from './FieldWrapper';
import CustomSelectInput from '../../common/CustomSelectInput';


type Props = {
    field: {
        onChange: (e: SyntheticInputEvent<number>) => void,
        onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
        onBlur: (e: SyntheticFocusEvent<any>) => void,
        value: any;
        name: string;
    },
    form: {
        setFieldValue: (string, any) => void,
        errors: Object,
        touched: Object,
        isSubmitting: boolean,
    },
    required: boolean,
    choices: {
        [any]: string,
    },
    many: boolean,
    label: string,
    help_text: string,
    empty_label: string,
    resource_name: string,
    search_field: string,
    label_fields: Array<string>,
    value_field: string,
    disabled?: boolean,
    creatable?: boolean,
}

const messages = defineMessages({
    required: {
        id: 'select-required',
        defaultMessage: 'This field is required.',
    },
});


const components = { Input: CustomSelectInput };


class Select extends React.Component<Props> {
    selectRef: React.Ref<BaseSelect>;

    static defaultProps = {
        empty_label: null,
        resource_name: null,
        search_field: 'search',
        label_fields: null,
        value_field: 'id',
    };

    static getValidationSchema(field: { validation: Object }) {
        let errMsg = Yup.string();

        if (field.validation.many) {
            errMsg = Yup.array().of(errMsg);
        }

        if (field.validation.required) {
            errMsg = Yup.string().required(messages.required);
        }

        return errMsg.nullable();
    }

    constructor(props: Props) {
        super(props);

        this.selectRef = React.createRef();
    }

    componentDidUpdate(prevProps: Props): void {
        const { field: { value } } = this.props;
        if (prevProps.field.value !== value) {
            if (
                this.selectRef.current
                && this.selectRef.current.select.current
                && this.selectRef.current.select.current.select
                && this.selectRef.current.select.current.select.inputRef
            ) {
                triggerNativeEvent(this.selectRef.current.select.current.select.inputRef, 'change');
            }
        }
    }

    handleChange = (value: ValueType) => {
        const { form: { setFieldValue }, field: { name } } = this.props;
        setFieldValue(name, value);
    };

    handleBlur = (e) => {
        const { field: { name, onBlur }, form: { setFieldTouched } } = this.props;
        setFieldTouched(name, true, false);
        onBlur(e);
    };

    render() {
        const {
            disabled,
            field: {
                name, value,
            },
            many,
            choices,
            label,
            help_text: help,
            required,
            empty_label: emptyLabel,
            resource_name: resourceName,
            value_field: valueField,
            label_fields: labelFields,
            search_field: searchField,
            form: { errors, touched, isSubmitting },
            creatable,
        } = this.props;

        const defaultEmptyMsg = emptyLabel || (
            <>
                <FormattedMessage
                    id="empty-select-label"
                    defaultMessage="No"
                />
                {
                    ` ${label.toLowerCase()}`
                }
            </>
        );

        return (
            <FieldWrapper
                name={name}
                required={required}
                helpText={help}
                label={label}
            >
                <BaseSelect
                    ref={this.selectRef}
                    name={name}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={typeof value !== 'undefined' ? value : null}
                    id={`id-${name}`}
                    multi={many}
                    choices={choices}
                    emptyLabel={emptyLabel || defaultEmptyMsg}
                    resourceName={resourceName}
                    searchField={searchField}
                    valueField={valueField}
                    labelFields={labelFields}
                    errors={get(errors, name)}
                    touched={get(touched, name)}
                    disabled={disabled || isSubmitting}
                    className="react-select"
                    classNamePrefix="react-select"
                    components={components}
                    creatable={creatable}
                />
            </FieldWrapper>
        );
    }
}

Select.getValidationSchema = (field: { validation: Object }) => {
    let errMsg = Yup.string().nullable();
    if (field.validation.required) {
        errMsg = Yup.string().required(messages.required).nullable();
    }

    return errMsg;
};

Select.defaultProps = {
    disabled: false,
    creatable: false,
};

export default Select;
