// @flow

import * as React from 'react';
import invariant from 'invariant';
import hoistStatics from 'hoist-non-react-statics';
import { ApiClient, shallowEqual } from 'speed-js-core';
import Context from './context';


type ConnectAPIState = {
    props: Object,
};


const mergeProps = (stateProps, dispatchProps, ownProps) => (
    { ...ownProps, ...stateProps, ...dispatchProps }
);


export function pureFinalPropsSelectorFactory(
    mapApiCallsToProps: (apiClient: ApiClient, props: Object) => Object,
    wrapCalls: (calls: Object) => Object,
    apiClient: ApiClient,
) {
    let hasRunAtLeastOnce = false;
    let stateProps;
    let ownProps;
    let apiProps;
    let mergedProps;

    const handleFirstCall = (firstStateProps: Object, firstOwnProps: Object) => {
        stateProps = firstStateProps;
        ownProps = firstOwnProps;
        apiProps = wrapCalls(mapApiCallsToProps(apiClient, ownProps));
        mergedProps = mergeProps(stateProps, apiProps, ownProps);
        hasRunAtLeastOnce = true;
        return mergedProps;
    };

    const handleNewPropsAndNewStateProps = (nextStateProps) => {
        stateProps = nextStateProps;

        apiProps = wrapCalls(mapApiCallsToProps(apiClient, ownProps));

        mergedProps = mergeProps(stateProps, apiProps, ownProps);
        return mergedProps;
    };

    const handleNewProps = () => {
        apiProps = wrapCalls(mapApiCallsToProps(apiClient, ownProps));

        mergedProps = mergeProps(stateProps, apiProps, ownProps);
        return mergedProps;
    };

    const handleNewStateProps = (nextStateProps) => {
        stateProps = nextStateProps;

        mergedProps = mergeProps(stateProps, apiProps, ownProps);

        return mergedProps;
    };

    const handleSubsequentCalls = (nextStateProps, nextOwnProps) => {
        const propsChanged = !shallowEqual(nextOwnProps, ownProps);
        const statePropsChanged = !shallowEqual(nextStateProps, stateProps);
        stateProps = nextStateProps;
        ownProps = nextOwnProps;

        if (propsChanged && statePropsChanged) return handleNewPropsAndNewStateProps(nextStateProps);
        if (propsChanged) return handleNewProps();
        if (statePropsChanged) return handleNewStateProps(nextStateProps);
        return mergedProps;
    };

    return (nextStateProps: Object, nextOwnProps: Object) => (
        hasRunAtLeastOnce
            ? handleSubsequentCalls(nextStateProps, nextOwnProps)
            : handleFirstCall(nextStateProps, nextOwnProps)
    );
}


const connectAPI = (
    mapApiCallsToProps: (props: Object) => {
        [name: string]: {
            send: (...args: Array<any>) => Promise<Object>,
            request?: () => Object,
            success?: (response: Object | Array<any>) => Object,
            error?: (response: Object | Array<any>) => Object,
        },
    },
    forwardRef: boolean = false,
    selectorFactory: (
        mapApiCallsToProps: (apiClient: ApiClient, props: Object) => Object,
        wrapCalls: (Object) => Object,
        apiClient: ApiClient,
    ) => ((stateProps: Object, ownProps: Object) => Object) = pureFinalPropsSelectorFactory,
): function => (
    (Wrapped: React.ComponentType<Object>): React.AbstractComponent<Object> => {
        const wrappedComponentName = Wrapped.displayName || Wrapped.name || 'Component';
        const displayName = `ConnectAPI(${wrappedComponentName})`;

        const makeDerivedPropsSelector = (wrapCalls: (call: function) => Function) => {
            let lastProps;
            let lastStateProps;
            let lastApiClient;
            let lastDerivedProps;
            let sourceSelector;

            return function selectDerivedProps(stateProps: Object, props: Object, apiClient: ApiClient) {
                if (shallowEqual(lastProps, props) && lastStateProps === stateProps) {
                    return lastDerivedProps;
                }

                lastProps = props;
                lastStateProps = stateProps;

                if (lastApiClient !== apiClient) {
                    sourceSelector = selectorFactory(mapApiCallsToProps, wrapCalls, apiClient);
                    lastApiClient = apiClient;
                }

                const nextProps = sourceSelector(stateProps, props);

                if (lastDerivedProps === nextProps) {
                    return lastDerivedProps;
                }

                lastDerivedProps = nextProps;
                return lastDerivedProps;
            };
        };

        const makeChildElementSelector = () => {
            let lastChildProps;
            let lastForwardRef;
            let lastChildElement;

            return function selectChildElement(childProps, forwardedRef) {
                if (childProps !== lastChildProps || forwardedRef !== lastForwardRef) {
                    lastChildProps = childProps;
                    lastForwardRef = forwardedRef;
                    lastChildElement = (
                        <Wrapped {...childProps} ref={forwardedRef} />
                    );
                }

                return lastChildElement;
            };
        };

        class ConnectAPI extends React.PureComponent<Object, Object> {
            selectChildElement: function;

            selectDerivedProps: function;

            mounted: boolean = false;

            state: ConnectAPIState = {
                props: {},
            };

            constructor(props) {
                super(props);

                this.selectChildElement = makeChildElementSelector();
                this.selectDerivedProps = makeDerivedPropsSelector(this.wrapAPICalls);
            }

            componentDidMount(): void {
                this.mounted = true;
            }

            componentWillUnmount(): void {
                this.mounted = false;
            }

            wrapAPICalls = (calls: {
                [name: string]: {
                    send: (...args: Array<any>) => Promise<Object>, request?: Object, success?: Object, error?: Object,
                },
            }): function => {
                const newCalls = {};
                Object.keys(calls).forEach((key) => {
                    const {
                        send, request, success, error,
                    } = calls[key];

                    newCalls[key] = (...args) => {
                        if (request) {
                            const props = request();

                            if (props) {
                                this.setState((state) => ({
                                    props: {
                                        ...state.props,
                                        ...props,
                                    },
                                }));
                            }
                        }
                        const result = send(...args);

                        return result.then((response) => {
                            if (this.mounted && success) {
                                const props = success(response, ...args);

                                if (props) {
                                    this.setState((state) => ({
                                        props: {
                                            ...state.props,
                                            ...props,
                                        },
                                    }));
                                }
                            }
                            return response;
                        }).catch((err) => {
                            if (this.mounted && error && err.name !== 'AbortError') {
                                const props = error(err, ...args);

                                if (props) {
                                    this.setState((state) => ({
                                        props: {
                                            ...state.props,
                                            ...props,
                                        },
                                    }));
                                }
                            }

                            return err;
                        });
                    };
                });

                return newCalls;
            };

            renderWrapped = (value: Object) => {
                invariant(
                    value,
                    `Could not find "apiClient" in the context of "${displayName}". 
Either wrap the root component in a <APIProvider>, 
or pass a custom React context provider to <APIProvider> and the corresponding 
React context consumer to ${displayName} in connect options.`,
                );
                const { apiClient } = value;

                const { forwardedRef, ...wrapperProps } = this.props;
                const props = forwardRef ? wrapperProps : this.props;

                const { props: stateProps } = this.state;

                const derivedProps = this.selectDerivedProps(stateProps, props, apiClient);

                return this.selectChildElement(derivedProps, forwardRef ? forwardedRef : undefined);
            };

            render() {
                return (
                    <Context.Consumer>
                        {this.renderWrapped}
                    </Context.Consumer>
                );
            }
        }

        const C = hoistStatics(ConnectAPI, Wrapped);

        if (forwardRef) {
            // eslint-disable-next-line
            return React.forwardRef((props: Object, ref: React.Ref<typeof React.Component>) => (
                <C {...props} forwardedRef={ref} />
            ));
        }
        return C;
    }
);

export default connectAPI;
