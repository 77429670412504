// @flow

import * as React from 'react';


type ProviderProps = {
    value: Object,
    children?: ?React.Node,
};

type ConsumerProps = {
    children: (value: Object) => ?React.Node,
};


export const APIContext: {
    Provider: React.ComponentType<ProviderProps>, Consumer: React.ComponentType<ConsumerProps>
} = React.createContext(null);

export default APIContext;
