// @flow
import { ACTIONS_NAMESPACE } from '../constants';

export const MOUNT_POINT = 'menu';

export const MENU_SET_CLASSNAMES = `${ACTIONS_NAMESPACE}/MENU_SET_CLASSNAMES`;
export const MENU_CONTAINER_ADD_CLASSNAME = `${ACTIONS_NAMESPACE}/MENU_CONTAINER_ADD_CLASSNAME`;
export const MENU_CLICK_MOBILE_MENU = `${ACTIONS_NAMESPACE}/MENU_CLICK_MOBILE_MENU`;
export const MENU_CHANGE_DEFAULT_CLASSES = `${ACTIONS_NAMESPACE}/MENU_CHANGE_DEFAULT_CLASSES`;
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = `${ACTIONS_NAMESPACE}/MENU_CHANGE_HAS_SUB_ITEM_STATUS`;
