if (typeof window.CustomEvent !== 'function') {
    // eslint-disable-next-line no-inner-declarations
    function CustomEvent(event, params) {
        // eslint-disable-next-line no-param-reassign
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
}


export const triggerCustomEvent = (element, eventName, data, params = {}) => {
    const event = new CustomEvent(eventName, { detail: data, ...params });
    element.dispatchEvent(event);
    return event;
};

export const triggerNativeEvent = (element, eventName, bubbles = true, cancelable = true) => {
    if (typeof window.Event === 'function') {
        element.dispatchEvent(new Event(eventName, { bubbles, cancelable }));
    } else {
        const event = document.createEvent('Event');
        event.initEvent(eventName, bubbles, cancelable);
        element.dispatchEvent(event);
    }
};
