// @flow
import * as React from 'react';
import { DatePicker } from 'speed-js-react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

type Props = {
    day: React.Element,
    month: React.Element,
    year: React.Element,
    intl: IntlShape,
    setValue: Function,
    value: string,
}

const msg = defineMessages({
    january: {
        id: 'january',
        defaultMessage: 'January',
    },
    february: {
        id: 'february',
        defaultMessage: 'February',
    },
    march: {
        id: 'march',
        defaultMessage: 'March',
    },
    april: {
        id: 'april',
        defaultMessage: 'April',
    },
    may: {
        id: 'may',
        defaultMessage: 'May',
    },
    june: {
        id: 'june',
        defaultMessage: 'June',
    },
    july: {
        id: 'july',
        defaultMessage: 'July',
    },
    august: {
        id: 'august',
        defaultMessage: 'August',
    },
    september: {
        id: 'september',
        defaultMessage: 'September',
    },
    october: {
        id: 'october',
        defaultMessage: 'October',
    },
    november: {
        id: 'november',
        defaultMessage: 'November',
    },
    december: {
        id: 'december',
        defaultMessage: 'December',
    },
});
// $FlowFixMe
const DatePickerWithMonths = (props: Props) => {
    const { intl } = props;
    const [monthChoices, setMonthChoices] = React.useState(null);

    React.useEffect(() => {
        const months = [];
        Object.keys(msg).map((message) => months.push(intl.formatMessage(msg[message])));
        setMonthChoices(months);
    }, [intl]);

    return (
        <>
            {monthChoices && (
                <DatePicker monthChoices={monthChoices} {...props} />
            )}
        </>
    );
};

export default injectIntl(DatePickerWithMonths);
