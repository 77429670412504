// @flow

import Resource from 'speed-js-core/src/api/Resource';

export default class NotificationResource extends Resource {
    basePath: string = 'notifications/notifications/';

    objectPath: string = 'notifications/notifications/{ident}/';

    count(contactDetailId): Promise<Object> {
        return this.client._request(
            // $FlowFixMe
            `${this._getBasePath()}count/?contact_detail=${contactDetailId}`,
            'GET',
        ).then((response) => response.data);
    }

    markSeen(contactDetailId): Promise<Object> {
        return this.client._request(
            // $FlowFixMe
            `${this._getBasePath()}seen/?contact_detail=${contactDetailId}`,
            'POST',
        ).then((response) => response.data);
    }

    markRead(contactDetailId): Promise<Object> {
        return this.client._request(
            // $FlowFixMe
            `${this._getBasePath()}read/?contact_detail=${contactDetailId}`,
            'POST',
        ).then((response) => response.data);
    }
}
