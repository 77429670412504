// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Label } from 'reactstrap';
import styled from 'styled-components';
import * as Yup from 'yup';
import DateTimePicker from './DateTimePicker';
import FieldWrapper from './FieldWrapper';

type Props = {
    field: {
        onChange: Function,
        onBlur: Function,
        value: any,
        name: string,
    },
    form: {
        setFieldTouched: Function,
        setFieldValue: Function,
        errors: string,
        values: Object,
    },
    required: boolean,
    label: string,
    help_text: string,
};

const ToDiv = styled.div`
    margin-top: 20px;
`;

const DateTimeRangePickerDropdown = (props: Props) => {
    const {
        form, field, label, required, help_text: helpText,
    } = props;

    const afterValue = form.values[`${field.name}_after`] || null;
    const beforeValue = form.values[`${field.name}_before`] || null;

    const setValueFrom = (valueFrom) => {
        form.setFieldValue(`${field.name}_after`, valueFrom);
    };

    const setValueTo = (valueTo) => {
        form.setFieldValue(`${field.name}_before`, valueTo);
    };

    return (
        <FieldWrapper
            label={label}
            name={field.name}
            required={required}
            helpText={helpText}
        >
            <div>
                <Label>
                    <FormattedMessage id="form.datetimerangepicker.label.from" defaultMessage="From:" />
                </Label>
                <DateTimePicker
                    setValue={setValueFrom}
                    value={afterValue}
                    {...props}
                />
            </div>
            <ToDiv>
                <Label>
                    <FormattedMessage id="form.datetimerangepicker.label.to" defaultMessage="To:" />
                </Label>
                <DateTimePicker
                    setValue={setValueTo}
                    value={beforeValue}
                    {...props}
                />
            </ToDiv>
        </FieldWrapper>
    );
};

DateTimeRangePickerDropdown.getValidationSchema = (field: { validation: Object }) => Yup.string().nullable();

export default DateTimeRangePickerDropdown;
