// @flow
import * as React from 'react';

type FieldProps = {
    required: boolean,
    value: string,
    placeholder: string,
    onChange: Function,
    name: string,
    onBlur: Function,
}

type Props = {
    lng: React.ComponentType<FieldProps>,
    lat: React.ComponentType<FieldProps>,
    lngValue: string,
    latValue: string,
    latComponentProps?: Object,
    lngComponentProps?: Object,
    lngPlaceholder: string,
    latPlaceholder: string,
    field: {
        value: number,
        name: string
    },
    onBlur: Function,
    onChange: Function,
    coordinates: {
        type: string,
        coordinates: [number, number]
    }
};

const CoordinatesPicker = (props: Props) => {
    const {
        field, lng: LngInput, lat: LatInput, lngValue, latValue,
        onBlur, onChange, lngPlaceholder, latPlaceholder, lngComponentProps,
        latComponentProps, coordinates,
    } = props;

    const { name } = field;

    return (
        <>
            <LngInput
                required
                value={lngValue || null}
                placeholder={lngPlaceholder}
                onChange={(e) => {
                    onChange(e, `${coordinates ? coordinates?.coordinates[0] : ''},${e.target.value}`);
                }}
                onBlur={onBlur}
                name={name}
                {...lngComponentProps}
            />
            <LatInput
                required
                value={latValue || null}
                placeholder={latPlaceholder}
                onChange={(e) => {
                    onChange(e, `${e.target.value},${coordinates ? coordinates?.coordinates[1] : ''}`);
                }}
                onBlur={onBlur}
                name={name}
                {...latComponentProps}
            />
        </>
    );
};

CoordinatesPicker.defaultProps = {
    latComponentProps: {},
    lngComponentProps: {},
};

export default CoordinatesPicker;
