// @flow
import Resource from 'speed-js-core/src/api/NestedResource';

export default class ServiceAccessNestedResource extends Resource {
    basePath: string = 'services/services/{parent}/access/';

    objectPath: string = 'services/services/{parent}/access/{ident}/';

    cancel(serviceId: string, serviceAccessId: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(serviceId, serviceAccessId)}cancel/`, 'PATCH',
        ).then((response) => response.data);
    }

    renew(serviceId: string, serviceAccessId: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(serviceId, serviceAccessId)}renew/`, 'PATCH',
        ).then((response) => response.data);
    }

    restrictNotificationType(serviceId: string, serviceAccessId: string, data: Object): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(serviceId, serviceAccessId)}restrict_notification_type/`, 'PATCH', data,
        ).then((response) => response.data);
    }

    derestrictNotificationType(serviceId: string, serviceAccessId: string, data: Object): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(serviceId, serviceAccessId)}derestrict_notification_type/`, 'PATCH', data,
        ).then((response) => response.data);
    }

    restrictAllNotificationTypes(serviceId: string, serviceAccessId: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(serviceId, serviceAccessId)}restrict_all_notification_types/`, 'PATCH',
        ).then((response) => response.data);
    }

    derestrictAllNotificationTypes(serviceId: string, serviceAccessId: string): Promise<Object> {
        return this.client._request(
            `${this._getObjectPath(serviceId, serviceAccessId)}derestrict_all_notification_types/`, 'PATCH',
        ).then((response) => response.data);
    }
}
