// @flow

import {
    MENU_SET_CLASSNAMES,
    MENU_CONTAINER_ADD_CLASSNAME,
    MENU_CLICK_MOBILE_MENU,
    MENU_CHANGE_DEFAULT_CLASSES,
    MENU_CHANGE_HAS_SUB_ITEM_STATUS,
} from './constants';


export type State = {
    containerClassNames: string,
    subHiddenBreakpoint: number,
    menuHiddenBreakpoint: number,
    menuClickCount: number,
    selectedMenuHasSubItems: boolean,
};


const INIT_STATE = {
    containerClassNames: 'menu-default',
    subHiddenBreakpoint: 1440,
    menuHiddenBreakpoint: 768,
    menuClickCount: 0,
    selectedMenuHasSubItems: false,
};

export default (state: State = INIT_STATE, action) => {
    switch (action.type) {
    case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
        return Object.assign({}, state, {
            selectedMenuHasSubItems: action.payload,
        });

    case MENU_SET_CLASSNAMES:
        return Object.assign({}, state, {
            containerClassNames: action.payload.containerClassNames,
            menuClickCount: action.payload.menuClickCount,
        });

    case MENU_CLICK_MOBILE_MENU:
        return Object.assign({}, state, {
            containerClassNames: action.payload.containerClassNames,
            menuClickCount: action.payload.menuClickCount,
        });

    case MENU_CONTAINER_ADD_CLASSNAME:
        return Object.assign({}, state, {
            containerClassNames: action.payload,
        });

    case MENU_CHANGE_DEFAULT_CLASSES:
        return Object.assign({}, state, {
            containerClassNames: action.payload,
        });

    default: return state;
    }
};
