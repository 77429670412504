// @flow

import GenericRelationResource from 'speed-js-core/src/api/resources/GenericRelationResource';

export default class CommentWithAttachmentResource extends GenericRelationResource {
    basePath: string = 'comments/{appLabel}/{contentType}/{objectId}';

    objectPath: string = 'comments/{appLabel}/{contentType}/{objectId}/{ident}/';

    withAttachment(appLabel: string, contentType: string, objectId: string, data: Object): Promise<Object> {
        return this.client._request(
            `${this._getBasePath(appLabel, contentType, objectId)}/with_attachment/`,
            'POST', data,
        ).then((response) => response.data);
    }
}
