// @flow
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
    hour: {
        id: 'form.timepicker.label.hour',
        defaultMessage: 'Hour',
    },
    minute: {
        id: 'form.timepicker.label.minute',
        defaultMessage: 'Minute',
    },
});

type Props = {
    hour: React.Element,
    minute: React.Element,
    hourValue: string,
    minuteValue: string,
    time: string,
    field: {
        value: number,
        name: string
    },
    col: React.Element,
    row: React.Element,
    onBlur: Function,
    onChange: Function,
    errors: Object,
};

const TimePicker = (props: Props) => {
    const {
        field, hour: HourInput, minute: MinuteInput, hourValue, minuteValue, time,
        col: Col, row: Row, onBlur, onChange, errors,
    } = props;
    const {
        value, name,
    } = field;
    const { formatMessage } = useIntl();

    return (
        <Row style={{ margin: 0 }} className={errors && 'is-invalid'}>
            <Col sm="6">
                <HourInput
                    required
                    value={hourValue}
                    onChange={(e) => {
                        const minutes = time ? time.split(':')[1] : '00';
                        let newValue;
                        if (e.target.value < 0) {
                            // Go back to 23 o'clock
                            newValue = `23:${minutes}`;
                        } else if (e.target.value > 23) {
                            newValue = `00:${minutes}`;
                        } else {
                            newValue = `${e.target.value}:${minutes}`;
                        }
                        onChange(e, newValue);
                    }}
                    onBlur={onBlur}
                    name={name}
                    label={formatMessage(messages.hour)}
                />
            </Col>
            <Col sm="6">
                <MinuteInput
                    required
                    value={minuteValue}
                    onChange={(e) => {
                        const hours = time ? time.split(':')[0] : '00';
                        let newValue;
                        if (e.target.value < 0) {
                            newValue = `${hours}:59`;
                        } else if (e.target.value > 59) {
                            newValue = `${hours}:00`;
                        } else {
                            newValue = `${hours}:${e.target.value}`;
                        }
                        onChange(e, newValue);
                    }}
                    onBlur={onBlur}
                    name={name}
                    label={formatMessage(messages.minute)}
                />
            </Col>
        </Row>
    );
};

export default TimePicker;
