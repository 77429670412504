// @flow
import Resource from 'speed-js-core/src/api/Resource';

export default class EmployeeRegisterResource extends Resource {
    basePath: string = 'entities/employees-register/';

    objectPath: string = 'entities/employees-register/{ident}/';

    schema(id: string, query: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(id), 'OPTIONS', query).then((response) => response.data);
    }

    update(resArg: string, id: string, data: Object): Promise<Object> {
        return this.client._request(this._getObjectPath(id), 'PUT', data).then((response) => response.data);
    }
}
