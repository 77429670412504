// @flow

import * as React from 'react';
import * as Yup from 'yup';
import DateTimePicker from './DateTimePicker';
import FieldWrapper from './FieldWrapper';

type Props = {
    field: {
        onChange: Function,
        onBlur: Function,
        value: any,
        name: string,
    },
    form: {
        setFieldTouched: Function,
        setFieldValue: Function,
        errors: string,
    },
    required: boolean,
    min_year: number,
    max_year: number,
    label: string,
    help_text: string,
};

const DateTimePickerDropdown = (props: Props) => {
    const {
        form, field, label, required, help_text: helpText, min_year: minYear, max_year: maxYear,
    } = props;

    const setValue = (value) => {
        form.setFieldValue(field.name, value);
    };

    return (
        <FieldWrapper
            label={label}
            name={field.name}
            required={required}
            helpText={helpText}
        >
            <DateTimePicker
                setValue={setValue}
                value={field.value}
                minYear={minYear}
                maxYear={maxYear}
            />
        </FieldWrapper>
    );
};

DateTimePickerDropdown.getValidationSchema = (field: { validation: Object }) => Yup.string().nullable();

export default DateTimePickerDropdown;
