// @flow
import Resource from 'speed-js-core/src/api/Resource';

export default class EBSDevice extends Resource {
    basePath: string = 'ebs/devices/';

    objectPath: string = 'ebs/devices/{ident}/';

    sync(): Promise<Object> {
        const basePath = this._getBasePath();
        return this.client._request(
            `${basePath}sync/`, 'GET',
        ).then((response) => response.data);
    }
}
