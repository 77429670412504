// @flow
import Resource from 'speed-js-core/src/api/Resource';
import PatrolActiveResource from './PatrolActiveResource';

export default class PatrolResource extends Resource {
    basePath: string = 'patrols/patrols/';

    objectPath: string = 'patrols/patrols/{ident}/';

    nestedResources = {
        active: PatrolActiveResource,
    };

    active: PatrolActiveResource;
}
