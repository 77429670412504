// @flow
import * as React from 'react';
import { withTheme } from 'styled-components';
import { memoize, get } from 'lodash';

import SyncSelect from './SyncSelect';
import AsyncSelect from './AsyncSelect';


type Props = {
    resourceName: string,
    errors: Object,
    setFirstVal: Function,
    additionalStyles: Object,
    emptyLabel: string,
}

const styleCallback = (props, styleKey) => (provided, state) => {
    const customSelect = (props.theme && props.theme.customSelect) ? props.theme.customSelect : {};

    const customStyle = get(customSelect, `default.${styleKey}`, {});

    return {
        ...provided,
        ...customStyle,
    };
};

const styles = memoize((props) => ({
    valueContainer: (provided, state) => {
        const customSelect = (props.theme && props.theme.customSelect) ? props.theme.customSelect : {};

        let valueContainer = get(customSelect, 'default.valueContainer');
        if (props.errors && props.touched) {
            valueContainer = {
                ...valueContainer,
                ...(props.theme && props.theme.customSelect && props.theme.customSelect.hasErrors
                    ? props.theme.customSelect.hasErrors.valueContainer
                    : {}
                ),
            };
        }

        return {
            ...provided,
            ...valueContainer,
        };
    },
    menuList: styleCallback(props, 'menuList'),
    indicatorsContainer: styleCallback(props, 'indicatorsContainer'),
    control: styleCallback(props, 'control'),
    option: styleCallback(props, 'option'),
    container: styleCallback(props, 'container'),
    multiValue: styleCallback(props, 'multiValue'),
    input: styleCallback(props, 'input'),
}));

const Cmp = (props: Props, ref) => {
    const { resourceName, errors, additionalStyles } = props;
    if (resourceName) {
        return (
            <div className={errors && 'is-invalid'}>
                <AsyncSelect styles={styles(props)} {...props} ref={ref} />
            </div>
        );
    }
    return (
        <div className={errors && 'is-invalid'}>
            <SyncSelect
                {...props}
                styles={{ ...styles(props), ...additionalStyles }}
                ref={ref}
            />
        </div>
    );
};

// $FlowFixMe
const BaseSelect = withTheme(React.memo(React.forwardRef(Cmp)));

BaseSelect.defaultProps = {
    resourceName: null,
    theme: {},
    additionalStyles: null,
};

export default BaseSelect;
