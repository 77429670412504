import ColorPicker from './ColorPicker';
import TextInput from './TextInput';
import TextArea from './TextArea';
import NumberInput from './NumberInput';
import Select from './Select';
import Checkbox from './Checkbox';
import PasswordInput from './PasswordInput';
import FieldWrapper from './FieldWrapper';
import DatePickerDropdown from './DatePickerDropdown';
import DatePickerWrapper from './DatePickerWrapper';
import DatePickerWithWrapper from './DatePickerWithWrapper';
import TimePickerInput from './TimePickerInput';
import CoordinatesPickerInput from './CoordinatesPickerInput';
import TranslationsInput from './TranslationsInput';
import FileInput from './FileInput';
import DateTimePickerDropdown from './DateTimePickerDropdown';
import DateTimeRangePickerDropdown from './DateTimeRangePickerDropdown';
import RichTextEditor from './RichTextEditor';
import JSONInput from './JSONInput';


export {
    TextInput, TextArea, NumberInput, Select, Checkbox, FieldWrapper, DatePickerDropdown, DatePickerWrapper,
    DatePickerWithWrapper, TimePickerInput, CoordinatesPickerInput, TranslationsInput, FileInput,
    DateTimePickerDropdown, DateTimeRangePickerDropdown, RichTextEditor,
};

export const REACTSTRACT_UI_DEFAULT_FIELDS = {
    text: TextInput,
    text_area: TextArea,
    number: NumberInput,
    password: PasswordInput,
    choice: Select,
    file: FileInput,
    boolean: Checkbox,
    date: DatePickerDropdown,
    time: TimePickerInput,
    coordinates: CoordinatesPickerInput,
    translations: TranslationsInput,
    color_picker: ColorPicker,
    datetime: DateTimePickerDropdown,
    datetime_range: DateTimeRangePickerDropdown,
    rich_text: RichTextEditor,
    json: JSONInput,
};

export const REACTSTRACT_UI_FALLBACK_FIELD_TYPE = 'text';
