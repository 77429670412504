// @flow
import { handleActions } from 'redux-actions';
import {
    SET_USER, REMOVE_USER, SET_PERMISSIONS, RESET_AUTH_STATE,
} from './constants';


export type User = {
    +username: string,
    +email: ?string,
    +firstName: ?string,
    +lastName: ?string,
    +id: string | number,
    [string]: any,
};

export type Permissions = {
    permissions: any,
    [string]: any,
};

export type State = {
    +user: ?User,
    +permissions: ?Permissions,
};

const initialState: State = {
    user: undefined,
    permissions: undefined,
};

const reducer = handleActions({
    [SET_USER]: (state: State, { payload }: {
        payload: {
            id: string | number,
            username: string,
            first_name: ?string,
            last_name: ?string,
            email: ?string,
            [string]: any,
        }
    }) => {
        const {
            first_name: firstName, last_name: lastName, id, username, email, ...rest
        } = payload;
        return {
            ...state,
            // $FlowFixMe
            user: {
                firstName,
                lastName,
                email,
                username,
                id,
                ...rest,
            },
        };
    },
    [REMOVE_USER]: (state: State) => ({
        ...state,
        user: null,
        permissions: null,
    }),
    [SET_PERMISSIONS]: (state: State, { payload }: {
        payload: {
            permissions: any,
            [string]: any,
        }
    }) => {
        const { permissions } = payload;
        return {
            ...state,
            permissions,
        };
    },
    [RESET_AUTH_STATE]: () => (initialState),
}, initialState);

export default reducer;
