// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage } from 'formik';
import {
    FormGroup, Label, FormText, FormFeedback,
} from 'reactstrap';
import { isObject } from 'lodash';
import RequiredAsterisk from '../RequiredAsterisk';


type Props = {
    name: string;
    children: React.Node,
    required: boolean,
    label: string,
    helpText: string,
}


const FieldWrapper = (props: Props): React.Node => {
    const {
        name, label, helpText, required, children,
    } = props;

    return (
        <FormGroup>
            {label && (
                <Label as="label" htmlFor={`id-${name}`}>
                    {label}
                    {required ? <RequiredAsterisk /> : null}
                </Label>
            )}
            {children}
            {helpText && (
                <FormText color="muted">{helpText}</FormText>
            )}
            <ErrorMessage name={name}>
                {(msg) => {
                    if (Array.isArray(msg)) {
                        return <FormFeedback valid={false} tooltip>{msg.join(' ')}</FormFeedback>;
                    }

                    if (isObject(msg)) {
                        return msg.defaultMessage ? (
                            <FormFeedback valid={false} tooltip>
                                <FormattedMessage {...msg} />
                            </FormFeedback>
                        ) : null;
                    }

                    return <FormFeedback valid={false} tooltip>{String(msg)}</FormFeedback>;
                }}
            </ErrorMessage>
        </FormGroup>
    );
};
export default FieldWrapper;
