// @flow
import Resource from 'speed-js-core/src/api/NestedResource';

export default class ServiceActionNestedResource extends Resource {
    basePath: string = 'services/services/{parent}/actions/';

    objectPath: string = 'services/services/{parent}/actions/{ident}/';

    execute(parentId: string, id: string, data: Object): Promise<Object> {
        const basePath = this._getObjectPath(parentId, id);
        return this.client._request(
            `${basePath}execute/`, 'POST', data,
        ).then((response) => response.data);
    }
}
