// @flow
import Resource from 'speed-js-core/src/api/Resource';
import ServiceAccessNestedResource from './ServiceAccessNestedResource';
import ServiceAssetNestedResource from './ServiceAssetNestedResource';
import ServiceZoneNestedResource from './ServiceZoneNestedResource';
import ServiceActionNestedResource from './ServiceActionNestedResource';

export default class ServiceResource extends Resource {
    basePath: string = 'services/services/';

    objectPath: string = 'services/services/{ident}/';

    nestedResources = {
        access: ServiceAccessNestedResource,
        asset: ServiceAssetNestedResource,
        zone: ServiceZoneNestedResource,
        actions: ServiceActionNestedResource,
    };

    turnOn(id: string, data: Object): Promise<Object> {
        return this.client._request(`${this._getObjectPath(id)}turn_on/`, 'PATCH', data)
            .then((response) => response.data);
    }

    turnOff(id: string, data: Object): Promise<Object> {
        return this.client._request(`${this._getObjectPath(id)}turn_off/`, 'PATCH', data)
            .then((response) => response.data);
    }
}
