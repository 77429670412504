// @flow

import type ApiClient from 'speed-js-core/src/api/ApiClient';
import type NestedResource from 'speed-js-core/src/api/NestedResource';

export default class ContactDetailResource {
    client: ApiClient;

    basePath: string = 'contacts/{app_name}/{model_name}/{object_id}/';

    nestedResources: { [string]: Class<NestedResource> };

    constructor(client: ApiClient) {
        this.client = client;
    }

    setNestedResources() {
        if (this.nestedResources) {
            Object.keys(this.nestedResources).forEach((sr) => {
                // $FlowFixMe
                this[sr] = new this.nestedResources[sr](this.client);
            });
        }
    }

    _getBasePath = (appName, modelName: string, objectId: string) => this.basePath
        .replace('{app_name}', appName)
        .replace('{model_name}', modelName)
        .replace('{object_id}', objectId);

    schema(appName: string, modelName: string, objectId: string): Promise<Object> {
        return this.client._request(this._getBasePath(appName, modelName, objectId), 'OPTIONS')
            .then((response) => response.data);
    }

    list(appName: string, modelName: string, objectId: string, query: Object): Promise<Object> {
        return this.client._request(this._getBasePath(appName, modelName, objectId), 'GET', query)
            .then((response) => response.data);
    }

    get(appName: string, modelName: string, objectId: string, query: Object): Promise<Object> {
        return this.client._request(this._getBasePath(appName, modelName, objectId), 'GET', query)
            .then((response) => response.data);
    }

    create(appName: string, modelName: string, objectId: string, data: Object): Promise<Object> {
        return this.client._request(this._getBasePath(appName, modelName, objectId), 'POST', data)
            .then((response) => response.data);
    }

    update(appName: string, modelName: string, objectId: string, data: Object, contactId: string): Promise<Object> {
        return this.client._request(`${this._getBasePath(appName, modelName, objectId)}${contactId}`, 'PUT', data)
            .then((response) => response.data);
    }

    partialUpdate(appName: string, modelName: string, objectId: string, data: Object,
        contactId: string): Promise<Object> {
        return this.client._request(`${this._getBasePath(appName, modelName, objectId)}${contactId}`, 'PATCH', data)
            .then((response) => response.data);
    }

    delete(appName: string, modelName: string, objectId: string, contactId: string): Promise<Object> {
        return this.client._request(`${this._getBasePath(appName, modelName, objectId)}${contactId}`, 'DELETE')
            .then((response) => response.data);
    }
}
