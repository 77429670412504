// @flow

import * as React from 'react';
import { defineMessages } from 'react-intl';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import DatePickerWithMonths from 'speed-js-react-web/src/components/fields/DatePickerWithMonths';
import * as Yup from 'yup';
import DatePickerWithWrapper from './DatePickerWithWrapper';
import FieldWrapper from './FieldWrapper';

type Props = {
    field: {
        onChange: Function,
        onBlur: Function,
        value: any,
        name: string,
    },
    form: {
        setFieldTouched: Function,
        setFieldValue: Function,
        errors: string,
    },
    required: boolean,
    label: string,
    help_text: string,
};

const StyledRow = styled(Row)`
    margin: 0px;
`;

const DatePickerDropdown = (props: Props) => {
    const {
        form, field, label, required, help_text: helpText,
    } = props;
    const handleSetValue = (v) => {
        form.setFieldValue(field.name, v);
    };
    return (
        <FieldWrapper
            label={label}
            name={field.name}
            required={required}
            helpText={helpText}
        >
            <div className={form.errors && 'is-invalid'}>
                <StyledRow className="no-pad-dropdown">
                    <DatePickerWithMonths
                        day={DatePickerWithWrapper}
                        month={DatePickerWithWrapper}
                        year={DatePickerWithWrapper}
                        setValue={handleSetValue}
                        value={field.value}
                        {...props}
                    />
                </StyledRow>
            </div>
        </FieldWrapper>
    );
};

const messages = defineMessages({
    required: {
        id: 'datepicker-required',
        defaultMessage: 'This field is required.',
    },
});

DatePickerDropdown.getValidationSchema = (field: { validation: Object }) => {
    if (field.validation.required) {
        return Yup.string().required(messages.required).nullable();
    }

    return Yup.string().nullable();
};

export default DatePickerDropdown;
