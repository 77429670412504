// @flow
import Resource from 'speed-js-core/src/api/Resource';

import EmployeeResource from './EmployeeResource';
import PatrolResource from './PatrolResource';
import PatrolDeviceResource from './PatrolDeviceResource';
import VehicleNestedResource from './VehicleNestedResource';

export default class ServiceProviderResource extends Resource {
    basePath: string = 'services/providers/providers/';

    objectPath: string = 'services/providers/providers/{ident}/';

    nestedResources = {
        employees: EmployeeResource,
        devices: PatrolDeviceResource,
        patrols: PatrolResource,
        vehicles: VehicleNestedResource,
    };

    employees: EmployeeResource;

    devices: PatrolDeviceResource;

    patrols: PatrolResource;

    vehicles: VehicleNestedResource;
}
