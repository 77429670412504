// @flow

import { memoize } from 'lodash';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import SchemaForm from 'speed-js-react/src/components/forms/SchemaForm';
import * as Yup from 'yup';
import Colxx from 'speed-react-gogo-tmpl/src/components/common/Colxx';
import { Row } from 'reactstrap';
import FieldWrapper from './FieldWrapper';


type Props = {
    field: {
        onChange: (e: SyntheticInputEvent<number>) => void,
        onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
        onBlur: (e: SyntheticFocusEvent<any>) => void,
        value: ?Array<Object>;
        name: string;
    },
    fields: Array,
    help_text: string,
    required: boolean,
    label: string,
    languages: Array<string>,
}

const messages = defineMessages({
    required: {
        id: 'translationsinput-required',
        defaultMessage: 'This field is required.',
    },
});

// eslint-disable-next-line react/prefer-stateless-function
class TranslationsInput extends React.Component<Props> {
    parseSchema: typeof SchemaForm.parseSchema;

    static getValidationSchema(field: { validation: Object, value: any,
        ui: { fields: Array, languages: Array<string> } }) {
        const result = {};
        Object.keys(field.ui.languages).forEach((language) => {
            result[language] = {};
            field.ui.fields.forEach((subField) => {
                if (subField[1].validation.required) {
                    result[language][subField[0]] = Yup.string().required(messages.required).nullable();
                }
            });
            result[language] = Yup.object(result[language]);
        });

        const errMsg = Yup.object(result);

        return errMsg.nullable();
    }

    static getEmptyInitialValues(field: { validation: Object, value: any,
        ui: { fields: Array, languages: Array<string> } }) {
        const result = {};

        Object.keys(field.ui.languages).forEach((language) => {
            result[language] = {};
            field.ui.fields.forEach((subField) => {
                result[language][subField[0]] = null;
            });
        });

        return result;
    }

    constructor(props: Props) {
        super(props);

        this.parseSchema = memoize(SchemaForm.parseSchema, (schema, prefix) => prefix);
    }

    schemaValues = (schema: Object) => Object.values(schema[0]);

    render() {
        const {
            field: { name }, fields, help_text: help, label, required, languages,
        } = this.props;

        const formFields = Object.keys(languages).map((language, index) => (
            <Colxx key={`${language}-box`}>
                <h4>{languages[language]}</h4>
                <Colxx pad="small" key={language}>
                    {this.schemaValues(this.parseSchema({ fields }, `${name}.${language}.`)).map((field) => (
                        React.cloneElement(field, { key: `${language}.${field.key}` })
                    ))}
                </Colxx>
            </Colxx>
        ));

        return (
            <FieldWrapper
                name={name}
                required={required}
                helpText={help}
                label={label}
            >
                <Row>
                    {formFields}
                </Row>
            </FieldWrapper>
        );
    }
}

export default TranslationsInput;
