// @flow
import * as React from 'react';
import { Input } from 'reactstrap';
import * as Yup from 'yup';
import {
    defineMessages,
} from 'react-intl';

type Props = {
    onChange: Function,
    value: string,
    placeholder: string,
    onBlur: Function,
    name: string,
}


const NumberInputCoordinates = (props: Props): React.Node => {
    const {
        value,
        onChange,
        onBlur,
        placeholder,
        name,
    } = props;

    return (
        <Input
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            value={value || ''}
            name={name}
            type="number"
        />
    );
};


const messages = defineMessages({
    required: {
        id: 'numberinput-required',
        defaultMessage: 'This field is required.',
    },
});

NumberInputCoordinates.getValidationSchema = (field: { validation: Object }) => {
    let errMsg = Yup.number().nullable();
    if (field.validation.required) {
        errMsg = Yup.number().required(messages.required).nullable();
    }

    return errMsg;
};

export default NumberInputCoordinates;
