// @flow

import Resource from '../Resource';

export default class AuthResource extends Resource {
    basePath: string = 'auth/';

    login(data: { username: string, password: string }): Promise<Object> {
        return this.client._request(`${this.basePath}login/`, 'POST', data, {}, 'omit');
    }

    logout(): Promise<Object> {
        return this.client._request(`${this.basePath}logout/`, 'POST');
    }

    loginSchema(): Promise<Object> {
        return this.client._request(`${this.basePath}login/`, 'OPTIONS', {}, {}, 'omit')
            .then((response) => response.data);
    }

    signup(data: Object, search?: Object) {
        let url = `${this.basePath}registration/`;

        if (search) {
            const _search = new URLSearchParams('?');
            Object.entries(search).forEach(([key, value]: [string, any]) => {
                _search.append(key, value);
            });

            url = `${url}?${_search.toString()}`;
        }

        return this.client._request(url, 'POST', data, {}, 'omit');
    }

    signupSchema(): Promise<Object> {
        return this.client._request(
            `${this.basePath}registration/`, 'OPTIONS', {}, {}, 'omit',
        ).then((response) => response.data);
    }

    get(): Promise<Object> {
        return this.client._request(`${this.basePath}user/`, 'GET').then((response) => response.data);
    }

    setUserDetails(data: Object): Promise<Object> {
        return this.client._request(`${this.basePath}user/`, 'PATCH', data)
            .then((response) => response.data);
    }

    setUserDetailsSchema(): Promise<Object> {
        return this.client._request(`${this.basePath}user/`, 'OPTIONS')
            .then((response) => response.data);
    }

    activate(key: string): Promise<Object> {
        return this.client._request(
            `${this.basePath}registration/verify-email/`,
            'POST',
            { key },
        ).then((response) => response.data);
    }

    getPermissions(): Promise<Object> {
        return this.client._request('authorisation/users/get_perm/', 'GET');
    }

    googleLogin(data: { access_token: string, code: string }): Promise<Object> {
        return this.client._request(`${this.basePath}google/login/`, 'POST', data, {}, 'omit');
    }

    changePassword(data: { old_password: string, new_password1: string, new_password2: string }): Promise<Object> {
        return this.client._request(`${this.basePath}password/change/`, 'POST', data);
    }

    changePasswordSchema(): Promise<Object> {
        return this.client._request(`${this.basePath}password/change/`, 'OPTIONS')
            .then((response) => response.data);
    }

    setForgottenPasswordEmail(data: { email: string }): Promise<Object> {
        return this.client._request(`${this.basePath}password/forgotten/`, 'POST', data);
    }

    forgottenPasswordEmailSchema(): Promise<Object> {
        return this.client._request(`${this.basePath}password/forgotten/`, 'OPTIONS')
            .then((response) => response.data);
    }

    refreshToken(data: { refresh: string }): Promise<Object> {
        // Manually call fetch to bypass the api client calling getHeaders again and causing a recursive
        // promise that never resolves!

        // $FlowIgnore
        const endpoint = this.client._getRequestEndpoint(`${this.basePath}token/refresh`, {});

        return fetch(
            endpoint,
            {
                method: 'POST',
                // cache: 'no-cache',
                credentials: 'same-origin',
                headers: this.client.getBaseHeaders(),
                body: JSON.stringify(data),
            },
        ).then((response) => (response.json()));
    }
}
