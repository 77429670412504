// @flow

import Resource from 'speed-js-core/src/api/Resource';
import NotificationTypeChannelResource from './NotificationTypeChannelResource';
import NotificationTypeScopeResource from './NotificationTypeScopeResource';
import NotificationTypeChannelNestedResource from './NotificationTypeChannelNestedResource';
import NotificationTypeScopeNestedResource from './NotificationTypeScopeNestedResource';

export default class NotificationTypeResource extends Resource {
    basePath: string = 'notifications/notification-types/';

    objectPath: string = 'notifications/notification-types/{ident}/';

    nestedResources = {
        notificationTypeChannels: NotificationTypeChannelResource,
        notificationTypeScopes: NotificationTypeScopeResource,
        notificationTypeChannelsNested: NotificationTypeChannelNestedResource,
        notificationTypeScopesNested: NotificationTypeScopeNestedResource,
    };

    notificationTypeChannels: NotificationTypeChannelResource

    notificationTypeScopes: NotificationTypeScopeResource

    notificationTypeChannelsNested: NotificationTypeChannelNestedResource

    notificationTypeScopesNested: NotificationTypeScopeNestedResource
}
