// @flow

import * as React from 'react';
import { BaseSelect } from 'speed-js-react-web';
import DatePickerWrapper from './DatePickerWrapper';

type Props = {
    label: string,
    onChange: Function,
    choices: Array,
    value: string,
    disabled: boolean,
};

const DatePickerWithWrapper = (props: Props) => {
    const {
        label, onChange, choices, value, disabled,
    } = props;
    return (
        <DatePickerWrapper label={label}>
            <BaseSelect onChange={onChange} choices={choices} value={value} disabled={disabled || null} />
        </DatePickerWrapper>
    );
};

export default DatePickerWithWrapper;
